// import { useEffect } from "react";

// const usePageSEO = (
//   title,
//   description,
//   keywords = [],
//   ogTitle,
//   ogDescription,
//   ogImage,
//   ogUrl
// ) => {
//   useEffect(() => {
//     document.title = title;
//     setMetaTag();
//     setMetaTag("name", "description", description);
//     setMetaTag("name", "keywords", keywords);
//     setMetaTag("property", "og:title", ogTitle || title);
//     setMetaTag("property", "og:description", ogDescription || description);
//     setMetaTag("property", "og:image", ogImage);
//     setMetaTag("property", "og:url", ogUrl || window.location.href);
//   }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl]);

//   const setMetaTag = (attr, key, content) => {
//     if (content) {
//       let element = document.querySelector(`meta[${attr}="${key}"]`);
//       if (!element) {
//         element = document.createElement("meta");
//         element.setAttribute(attr, key);
//         document.head.appendChild(element);
//       }
//       element.setAttribute("content", content);
//     }
//   };
// };

// export default usePageSEO;


import { useEffect } from "react";

const usePageSEO = (
  title,
  description,
  keywords = [],
  ogTitle,
  ogDescription,
  ogImage,
  ogUrl
) => {
  useEffect(() => {
    document.title = title;
    setMetaTag();
    setMetaTag("name", "description", "Experience the vibrant Suvarna Navratri 2024 with Kirtidan Gadhvi. Book your tickets on Lovemyshow for an unforgettable Garba night filled with music and tradition");
    setMetaTag("name", "keywords", keywords);
    setMetaTag("property", "og:title", "Don’t Miss Suvarna Navratri 2024 with Kirtidan Gadhvi – Book Your Tickets Now!");
    setMetaTag("property", "og:description", ogDescription || description);
    setMetaTag("property", "og:image", ogImage);
    setMetaTag("property", "og:url", ogUrl || window.location.href);
  }, [title, description, keywords, ogTitle, ogDescription, ogImage, ogUrl]);

  const setMetaTag = (attr, key, content) => {
    if (content) {
      let element = document.querySelector(`meta[${attr}="${key}"]`);
      if (!element) {
        element = document.createElement("meta");
        element.setAttribute(attr, key);
        document.head.appendChild(element);
      }
      element.setAttribute("content", content);
    }
  };
};

export default usePageSEO;
