// /* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect, useState } from "react";
// import {
//   MdKeyboardArrowDown,
//   MdKeyboardArrowUp,
//   MdOutlinePayments,
// } from "react-icons/md";
// import usePageSEO from "../hoosk/usepageSEO/Index";
// import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
// import {
//   CheckTicketOrder,
//   CreateTicketOrder, 
// } from "../API/EventApi";
// import { FaAddressCard, FaDollarSign, FaRupeeSign } from "react-icons/fa";
// import lmsicon from "../../Images/lms-icon-white.png";
// import { useFormik } from "formik";
// import { IoIosInformationCircleOutline } from "react-icons/io";
// import * as yup from "yup";
// import { sendOTP, verifyOTP } from "../API/LoginApi";
// import { RxCross2 } from "react-icons/rx";
// import Config from "../API/Config";
// import BeatLoader from "react-spinners/BeatLoader";
// import { toast } from "react-toastify";
// import FormLoader from "../common/FormLoader";
// import { FaCalendar, FaCalendarCheck, FaLocationDot } from "react-icons/fa6";
// import ScrollToTop from "../ScrollToTop";

// const phoneLengthByCountry = {
//   India: 10, // India
//   Australia: 10, // Australia
//   UK: 10, // UK
//   USA: 10, // USA
//   Canada: 10, // Canada
//   // Add other countries and their rules here
// };
// const getPhoneValidationSchema = (countryCode) => {
//   const phoneLength = phoneLengthByCountry[countryCode] || 10;
//   return yup
//     .string()
//     .required(`Phone number is required for ${countryCode}`)
//     .test(
//       "is-valid-length",
//       `Enter a valid phone number of length ${phoneLength}`,
//       (value) => value && value.replace(/\D/g, "").length === phoneLength
//     );
// };
// const validationSchema2 = yup.object().shape({
//   OTP: yup
//     .string()
//     .matches(/^[0-9]+$/, "OTP must contain only numbers")
//     .length(4, "OTP must be exactly 4 digits")
//     .required("OTP is required"),
// });

// const BookingSteps4 = () => {
//   const getCountrySlug = Config.getCountrySlug();
//   const validationSchema = yup.object().shape({
//     EOP: yup.string().when("Country", {
//       is: (value) => !!value, // Ensure Country is selected
//       then: (value) => getPhoneValidationSchema(getCountrySlug),
//       otherwise: yup.string().required("Phone number is required"),
//     }),
//   });
//   usePageSEO(
//     "Love My Show ", // title
//     "Love My Show ", // description
//     ["Love My Show  "] // keywords
//   );
//   const UserId = Config.getId();
//   const UserType = Config.getType();

//   const navigate = useNavigate();
//   const location = useLocation();
//   const bookingDetails = location.state;
//   const [EventAddressData, setEventAddressData] = useState([]);
//   const [isShowMore, setIsShowMore] = useState(false);
//   const [appliedCouponAmount, setAppliedCouponAmount] = useState(0);
//   const [CouponId, setCouponId] = useState(0);
//   const [couponCode, setCouponCode] = useState("");
//   const toggleReadMoreLess = () => {
//     setIsShowMore(!isShowMore);
//   };
//   const { EventId, AddressId, DateId, TicketId } = useParams();
//   const [coupan, setcoupan] = useState([]);
//   useEffect(() => {
//     const fetchEventAddressData = async () => {
//       try {
//         setEventAddressData(bookingDetails);
//         const ticketQty = bookingDetails?.total?.Qty;
//         formik3.setFieldValue("Qty", ticketQty); // Set Qty in formik
//         setcoupan(bookingDetails?.Coupon);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setLoading(false); // Set loading to false after data is fetched
//       }
//     };
//     fetchEventAddressData();
//   }, []);

//   const [isFormLoading, setIsFormLoading] = useState(false);
//   const formik3 = useFormik({
//     initialValues: {
//       UserId: UserId,
//       EventId: EventId,
//       AddressId: AddressId,
//       DateId: DateId,
//       TicketId: TicketId,
//       CouponId: CouponId,
//       Qty: "",
//     },
//     onSubmit: async (values) => {
//       setIsFormLoading(true);

//       try {
//         // Step 1: Check ticket availability
//         const checkResponse = await CheckTicketOrder({
//           TicketId: values.TicketId,
//           Qty: values.Qty,
//         });

//         if (checkResponse.status === true) {
//           const createResponse = await CreateTicketOrder(values);
//           if (createResponse.status === true) {
//             window.location.href = createResponse.redirectUrl;
//           } else {
//             navigate(`/event/booking-steps/${EventId}/${AddressId}/${DateId}`);
//           }
//         } else {
//           // Redirect to home page if tickets are not available
//           navigate(`/home`);
//         }
//       } catch (error) {
//         console.error("Error:", error);
//         // Optionally, handle errors here (e.g., show an alert or error message)
//       } finally {
//         setIsFormLoading(false); // Set loading state to false when submission ends
//       }
//     },
//   });
//   // Convert EventStartDateTime to a Date object
//   const startDate = new Date(EventAddressData?.date?.EventDate);
//   const formattedStartDate = `${startDate
//     .getDate()
//     .toString()
//     .padStart(2, "0")}/${(startDate.getMonth() + 1)
//     .toString()
//     .padStart(2, "0")}/${startDate.getFullYear()}`;

//   // --------------------Login-----------------
//   const [loginpopup, setloginpopup] = useState(false);
//   const handleProfileClick = () => {
//     setloginpopup(true);
//   };
//   const [isMobileScreen, setIsMobileScreen] = useState(true);
//   const [showResend, setShowResend] = useState(false);
//   const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
//   // -----------------------otpsend-------------------------
//   useEffect(() => {
//     let interval;
//     if (timer > 0) {
//       interval = setInterval(() => {
//         setTimer((prevTimer) => prevTimer - 1);
//       }, 1000);
//     } else {
//       setShowResend(true);
//     }

//     return () => clearInterval(interval);
//   }, [timer]);

//   const formik = useFormik({
//     initialValues: {
//       EOP: "", // Add EOP field
//       Country: getCountrySlug,
//       Type: "U",
//     },
//     validationSchema: validationSchema,
//     onSubmit: async (values, actions) => {
//       setIsFormLoading(true);
//       try {
//         const response = await sendOTP(values);
//         if (response.status === true) {
//           setIsMobileScreen(false);
//         }
//         formik2.setFieldValue("EOP", values.EOP);
//         setResendOTP((prevState) => ({
//           ...prevState,
//           EOP: values.EOP, // Update EOP in ResendOTP state
//         }));
//         actions.resetForm();
//       } catch (error) {
//         console.error("Error :", error);
//       } finally {
//         setIsFormLoading(false); // Set loading state to false when submission ends
//       }
//     },
//   });
//   const formik2 = useFormik({
//     initialValues: {
//       EOP: "",
//       Country: getCountrySlug,
//       Type: "U",
//       OTP: "",
//     },
//     validationSchema: validationSchema2,
//     onSubmit: async (values, actions) => {
//       setIsFormLoading(true);
//       try {
//         const response = await verifyOTP(values);
//         if (response.status === true) {
//           window.location.reload();
//           setloginpopup(false);
//         }

//         actions.resetForm();
//       } catch (error) {
//         console.error("Error :", error);
//       } finally {
//         setIsFormLoading(false); // Set loading state to false when submission ends
//       }
//     },
//   });

//   const [ResendOTP, setResendOTP] = useState({
//     EOP: "",
//     Country: getCountrySlug,
//     Type: "U",
//   });
//   const handleResendOTP = async () => {
//     setIsFormLoading(true);
//     try {
//       setTimer(60);
//       setShowResend(false);
//       await sendOTP(ResendOTP); // Replace with actual function call
//     } catch (error) {
//       console.error("Error resending OTP:", error);
//     } finally {
//       setIsFormLoading(false); // Set loading state to false when submission ends
//     }
//   };
//   const formatTimer = () => {
//     const minutes = Math.floor(timer / 60);
//     const seconds = timer % 60;
//     return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
//   };

//   const handleLoginClick = () => {
//     toast.info("To book a ticket, you must be registered as a user.", {
//       autoClose: 2000,
//       onClose: () => {
//         setloginpopup(true);
//       },
//     });
//   };

//   const [loading, setLoading] = useState(true); // Loading state
//   // Handle coupon code application
//   const applyCouponCode = () => {
//     const selectedCoupon = coupan.find((val) => val.Code === couponCode);
//     if (selectedCoupon) {
//       setAppliedCouponAmount(selectedCoupon.Amount);
//       setCouponId(selectedCoupon.Id);
//       formik3.setFieldValue("CouponId", selectedCoupon.Id); // Set Qty in formik
//     } else {
//       setAppliedCouponAmount(0);
//     }
//   };

//   // Calculate the total amount after applying the coupon
//   const calculateTotalAmount = () => {
//     const subtotal = EventAddressData?.total?.subTotal || 0;
//     const charge = EventAddressData?.total?.charge || 0;
//     const discount = appliedCouponAmount || 0;
//     return subtotal + charge - discount;
//   };

//   usePageSEO(
//     "Love My Show | Book Ticket", // title
//     "Love My Show | Book Ticket", // description
//     ["Love My Show | Book Ticket"] // keywords
//   );
//   return (
//     <div className="py-5 container mx-auto ">
//       <ScrollToTop />
//       {isFormLoading && <FormLoader loading={isFormLoading} />}
//       <div className="w-full py-4 px-4">
//         <form onSubmit={formik3.handleSubmit}>
//           <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
//             <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r from-themecolor1 to-themecolor2"></div>
//             <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r bg-gray-300  transition-all duration-500"></div>
//             <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//               <img src={lmsicon} alt="" className="w-6 h-6" />
//             </div>
//             <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//               <FaCalendar className="w-5 h-5" />
//             </div>
//             <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//               <FaAddressCard className="w-5 h-5" />
//             </div>
//             <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
//               <MdOutlinePayments className="w-5 h-5" />
//             </div>
//           </div>
//           <div className="my-10">
//             <div className="dark:bg-boxdark-2 dark:text-white  ">
//               <div className="container mx-auto ">
//                 <div className="grid grid-cols-1 lg:grid-cols-3 dark:bg-boxdark-2 dark:text-white  ">
//                   <div className="col-span-2 bg-gray-100 dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 px-5">
//                     <h1 className="py-4 text-gray-500 dark:text-white md:text-normal text-sm font-medium">
//                       Please select from the following option(s)
//                     </h1>
//                     <div>
//                       <div>
//                         <div className="flex mb-2">
//                           <input
//                             type="radio"
//                             name="PaymentMethod"
//                             id="mTicket"
//                             value="0"
//                             checked
//                             onChange={formik3.handleChange}
//                           />
//                           <label htmlFor="mTicket" className="ml-2 text-sm">
//                             M-Ticket
//                           </label>
//                         </div>
//                       </div>

//                       <div className="bg-gray-200 dark:bg-boxdark-2 dark:text-white md:text-normal text-sm dark:border dark:border-zinc-600 p-3 mb-4">
//                         <h1 className="font-semibold">M-Ticket Information</h1>
//                         <p className="py-1 md:text-sm text-xs">
//                           1. Customer(s) can access their tickets(s) from the
//                           'My Profile' section on the app/mobile-web.
//                         </p>
//                         <p className="py-1 md:text-sm text-xs">
//                           2. It is mandatory to present the ticket(s) in my
//                           profile section via app/mobile-web at the venue.
//                         </p>
//                         <p className="py-1 md:text-sm text-xs">
//                           3. No physical ticket(s) are required to enter the
//                           venue
//                         </p>
//                       </div>
//                     </div>

//                     {formik3.touched.PaymentMethod &&
//                     formik3.errors.PaymentMethod ? (
//                       <div className="text-red-500 text-xs mt-2">
//                         {formik3.errors.PaymentMethod}
//                       </div>
//                     ) : null}

//                     {coupan.length === 0 ? (
//                       ""
//                     ) : (
//                       <div className="my-4">
//                         <label htmlFor="couponCode" className="mb-2 text-sm">
//                           Coupon Code
//                         </label>
//                         <div className="mt-1">
//                           <div className="flex w-full md:w-96">
//                             <select
//                               id="couponCode"
//                               className="border dark:border-zinc-600 py-3 rounded-tl-lg rounded-bl-lg text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white "
//                               name="couponCode"
//                               onChange={(e) => setCouponCode(e.target.value)}
//                             >
//                               <option value="">Select Coupon</option>
//                               {coupan?.map((val, index) => (
//                                 <option key={index} value={val.Code}>
//                                   {val.Code}
//                                 </option>
//                               ))}
//                             </select>
//                             <button
//                               type="button"
//                               className="bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-r-lg text-white px-5"
//                               onClick={applyCouponCode}
//                             >
//                               Apply
//                             </button>
//                           </div>
//                         </div>
//                       </div>
//                     )}
//                   </div>

//                   <div className="bg-gray-100 lg:mx-5 pt-5 mt-0 py-5 dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600">
//                     <div className="grid grid-cols-2 md:grid-cols-2 border border-gray-300 mx-2 rounded-md bg-white dark:bg-boxdark-2 mb-3 ">
//                       <div className="text-black dark:text-white py-2 px-5 border-b dark:border-zinc-600">
//                         <h1 className="md:text-normal text-sm font-semibold">
//                           {EventAddressData?.event?.EventName}
//                         </h1>
//                         <p className="text-sm text-gray-500 dark:text-white">
//                           <b className="text-xs">Ticket Qty: </b>
//                           {EventAddressData?.total?.Qty}
//                         </p>
//                       </div>

//                       <h1 className="py-2 pr-5 border-b text-themecolor1 dark:border-zinc-600 flex justify-end items-center">
//                         {getCountrySlug === "India" ? (
//                           <>
//                             <span className="text-sm">
//                               <FaRupeeSign />
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <span className="text-sm">
//                               <FaDollarSign />
//                             </span>
//                           </>
//                         )}{" "}
//                         {EventAddressData?.total?.Price}
//                       </h1>
//                     </div>
//                     <div className="grid grid-cols-1 md:grid-cols-1 border dark:bg-boxdark-2 border-gray-300 mx-2 rounded-md py-2 px-5 text-sm bg-white mb-3">
//                       <div className="flex items-center">
//                         <FaCalendarCheck className="text-xl mr-2" />
//                         <div>
//                           <p className="mt-2">
//                             <b className="md:text-sm text-xs">Event Date:</b>
//                           </p>
//                           <p className=" pb-2 md:text-sm text-xs text-gray-500 dark:text-white">
//                             {formattedStartDate}- (
//                             {EventAddressData?.date?.EventStartTime} -{" "}
//                             {EventAddressData?.date?.EventEndTime})
//                           </p>
//                         </div>
//                       </div>
//                       <div className="flex items-center">
//                         <FaLocationDot className="text-xl mr-2" />
//                         <div>
//                           <p className="mt-2">
//                             <b className="md:text-sm text-xs">Venue</b>
//                           </p>
//                           <p className=" pb-2 md:text-sm text-xs text-gray-500 dark:text-white text-justify">
//                             {EventAddressData?.address?.Location} ,
//                             {EventAddressData?.address?.CityTitle} ,
//                             {EventAddressData?.address?.CountryTitle}
//                           </p>
//                         </div>
//                       </div>
//                       <h1 className="md:text-sm text-xs border-t dark:border-zinc-600 py-2">
//                         {EventAddressData?.ticket?.TicketName} (
//                         {EventAddressData?.total?.Price}) :{" "}
//                         {EventAddressData?.total?.Qty} ticket(s)
//                       </h1>
//                     </div>
//                     <div className="grid grid-cols-2 mt-4">
//                       <p className="px-5 md:text-normal text-sm">Sub Total</p>
//                       <p className="flex justify-end px-5 md:text-normal text-sm items-center ">
//                         {" "}
//                         {getCountrySlug === "India" ? (
//                           <>
//                             <span className="text-sm">
//                               <FaRupeeSign />
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <span className="text-sm">
//                               <FaDollarSign />
//                             </span>
//                           </>
//                         )}{" "}
//                         {EventAddressData?.total?.subTotal}
//                       </p>
//                     </div>
//                     <div className="card py-2 px-5">
//                       <button
//                         onClick={toggleReadMoreLess}
//                         type="button"
//                         className="flex md:text-normal text-sm w-full border-b dark:border-zinc-600 pb-2"
//                       >
//                         Platform Fee
//                         {isShowMore ? (
//                           <MdKeyboardArrowUp className="mt-1" />
//                         ) : (
//                           <MdKeyboardArrowDown className="mt-1" />
//                         )}
//                         <h1 className="flex ml-auto md:text-normal text-sm items-center">
//                           {" "}
//                           {getCountrySlug === "India" ? (
//                             <>
//                               <span className="text-sm">
//                                 <FaRupeeSign />
//                               </span>
//                             </>
//                           ) : (
//                             <>
//                               <span className="text-sm">
//                                 <FaDollarSign />
//                               </span>
//                             </>
//                           )}{" "}
//                           {EventAddressData?.total?.charge}
//                         </h1>
//                       </button>
//                       {isShowMore && (
//                         <div className="border-b dark:border-zinc-600 pb-1">
//                           <div className="flex py-2">
//                             <p className="md:text-normal text-sm w-full">Base Price</p>
//                             <h1 className="flex md:text-normal text-sm items-center">
//                               {getCountrySlug === "India" ? (
//                                 <>
//                                   <span className="md:text-normal text-sm">
//                                     <FaRupeeSign />
//                                   </span>
//                                 </>
//                               ) : (
//                                 <>
//                                   <span className="md:text-normal text-sm">
//                                     <FaDollarSign />
//                                   </span>
//                                 </>
//                               )}{" "}
//                               {EventAddressData?.total?.subtotalExcludingGSTTOTAL.toFixed(
//                                 2
//                               )}
//                             </h1>
//                           </div>
//                           <div className="flex ">
//                             <p className="md:text-normal text-sm w-full">
//                               Inclusive GST %{" "}
//                               {EventAddressData?.total?.gstPercentage}
//                             </p>
//                             <h1 className="flex md:text-normal text-sm items-center">
//                               {getCountrySlug === "India" ? (
//                                 <>
//                                   <span className="md:text-normal text-sm">
//                                     <FaRupeeSign />
//                                   </span>
//                                 </>
//                               ) : (
//                                 <>
//                                   <span className="md:text-normal text-sm">
//                                     <FaDollarSign />
//                                   </span>
//                                 </>
//                               )}{" "}
//                               {EventAddressData?.total?.gstAmount.toFixed(2)}
//                             </h1>
//                           </div>
//                         </div>
//                       )}
//                     </div>
//                     {appliedCouponAmount === 0 ? (
//                       ""
//                     ) : (
//                       <div className="grid grid-cols-2">
//                         <p className="px-5">Coupon Discount</p>
//                         <p className="flex justify-end px-5 items-center">
//                           {getCountrySlug === "India" ? (
//                             <>
//                               <span className="text-sm">
//                                 <FaRupeeSign />
//                               </span>
//                             </>
//                           ) : (
//                             <>
//                               <span className="text-sm">
//                                 <FaDollarSign />
//                               </span>
//                             </>
//                           )}{" "}
//                           {appliedCouponAmount}
//                         </p>
//                       </div>
//                     )}
//                     <div className="flex px-5 pb-4 border-b">
//                       <h1 className="w-full md:text-normal text-sm">Total Amount</h1>
//                       <h1 className="flex items-center md:text-normal text-sm">
//                         {getCountrySlug === "India" ? (
//                           <>
//                             <span className="md:text-normal text-sm">
//                               <FaRupeeSign />
//                             </span>
//                           </>
//                         ) : (
//                           <>
//                             <span className="md:text-normal text-sm">
//                               <FaDollarSign />
//                             </span>
//                           </>
//                         )}{" "}
//                         {calculateTotalAmount()}
//                       </h1>
//                     </div>

//                     <h1 className="flex items-center md:text-xs text-[11px] px-2 text-gray-400 mt-5 border-b dark:border-zinc-600 pb-4">
//                       <IoIosInformationCircleOutline className="text-2xl mx-2" />
//                       By proceeding, I express my consent to complete this
//                       transaction
//                     </h1>

//                     {formik3.isSubmitting ? (
//                       <div style={{ position: "relative" }} className="mt-3">
//                         <div className="flex justify-center items-center">
//                           <BeatLoader
//                             color={"#DC3558"}
//                             loading={loading}
//                             size={18}
//                           />
//                         </div>
//                         <div
//                           style={{
//                             position: "absolute",
//                             bottom: "0",
//                             left: "50%",
//                             transform: "translateX(-50%)",
//                           }}
//                         ></div>
//                       </div>
//                     ) : (
//                       <div>
//                         {formik3.values.UserId === null ? (
//                           <button
//                             type="button"
//                             onClick={() => handleProfileClick(true)}
//                             className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
//                           >
//                             Login To Proceed
//                           </button>
//                         ) : UserType === "V" ? (
//                           <button
//                             type="button"
//                             onClick={() => handleLoginClick()}
//                             className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
//                           >
//                             Login To Proceed
//                           </button>
//                         ) : (
//                           <button
//                             type="submit"
//                             className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
//                           >
//                             Pay
//                           </button>
//                         )}
//                       </div>
//                     )}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="flex justify-between mt-10">
//             <div>
//               <Link
//                 to={`/event/booking-steps/${EventId}/${AddressId}/${DateId}`}
//                 className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor2 to-themecolor1 text-white shadow-lg hover:scale-105 transition-all"
//               >
//                 Previous
//               </Link>
//             </div>
//           </div>
//         </form>
//       </div>
//       {loginpopup ? (
//         <div>
//           <div className="px-3 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
//             <div className="relative  my-6 mx-auto w-96">
//               <div className="border-0 rounded-lg shadow-lg relative h-80 flex flex-col w-full bg-white  outline-none dark:bg-boxdark-2 dark:text-white   focus:outline-none">
//                 <div className="flex items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t dark:border-zinc-600">
//                   <h3 className="text-xl  block font-semibold">Login</h3>
//                   <button
//                     className="text-themecolor1 background-transparent font-bold uppercase  text-3xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
//                     type="button"
//                     onClick={() => setloginpopup(false)}
//                   >
//                     <RxCross2 />
//                   </button>
//                 </div>
//                 {isMobileScreen ? (
//                   <div>
//                     <form onSubmit={formik.handleSubmit}>
//                       <p className="text-center text-xl mt-2">
//                         Enter Mobile No
//                       </p>
//                       <div className="p-6 pt-2 border-solid border-blueGray-200 pb-6 rounded-b">
//                         <div className="flex border-0 w-full">
//                           <input
//                             type="text"
//                             name="EOP"
//                             autoFocus
//                             value={formik.values.EOP}
//                             onChange={formik.handleChange}
//                             onBlur={formik.handleBlur}
//                             maxLength={phoneLengthByCountry[getCountrySlug] || 10}
//                             className="border dark:border-zinc-600 outline-none w-full p-2 rounded-md dark:bg-boxdark-2 dark:text-white"
//                             placeholder="Enter Mobile Number"
//                           />
//                         </div>
//                         {formik.touched.EOP && formik.errors.EOP ? (
//                           <div className="text-red-500 text-xs mt-2">
//                             {formik.errors.EOP}
//                           </div>
//                         ) : null}
//                       </div>
//                       <div className="px-6">
//                         <button
//                           className="bg-themecolor1 p-2 w-full text-white rounded-md"
//                           type="submit"
//                         >
//                           Continue
//                         </button>
//                       </div>
//                       <p className="text-xs block px-6 m-auto text-gray-500 py-5 dark:text-white">
//                         I agree to the{" "}
//                         <Link
//                           to={"/page/terms-and-conditions"}
//                           className="underline decoration-1"
//                         >
//                           Terms & conditions
//                         </Link>{" "}
//                         &{" "}
//                         <Link
//                           to={"/page/privacy-policy"}
//                           className="underline decoration-1"
//                         >
//                           Privacy Policy
//                         </Link>
//                       </p>
//                     </form>
//                   </div>
//                 ) : (
//                   <div className="my-auto">
//                     <form onSubmit={formik2.handleSubmit}>
//                       <p className="text-center text-xl">Enter OTP</p>
//                       <div className="p-3  w-full flex justify-center">
//                         <input
//                           type="text"
//                           placeholder="****"
//                           name="OTP"
//                           autoFocus
//                           maxLength={4}
//                           value={formik2.values.OTP}
//                           onChange={formik2.handleChange}
//                           className="border text-center w-full dark:bg-boxdark-2 dark:text-white py-3  mx-2 tracking-wide"
//                         />
//                       </div>
//                       {formik2.touched.OTP && formik2.errors.OTP ? (
//                         <div className="text-red-500 text-xs mt-2 px-5">
//                           {formik2.errors.OTP}
//                         </div>
//                       ) : null}
//                       <div className="text-center text-xs mt-3">
//                         {showResend ? (
//                           <>
//                             OTP expired?{" "}
//                             <button
//                               onClick={handleResendOTP}
//                               type="button"
//                               className="underline text-themecolor1"
//                             >
//                               Resend OTP
//                             </button>
//                           </>
//                         ) : (
//                           `Resend OTP in ${formatTimer()}`
//                         )}
//                       </div>
//                       <div className="grid grid-cols-2 mt-7">
//                         <button
//                           className="bg-themecolor1 mx-5 p-2 text-white rounded-md"
//                           onClick={() => setIsMobileScreen(true)}
//                           type="button"
//                         >
//                           Back
//                         </button>
//                         <button
//                           type="submit"
//                           className="bg-themecolor1 mx-5 p-2 text-white text-center rounded-md"
//                         >
//                           Verify OTP
//                         </button>
//                       </div>
//                     </form>
//                   </div>
//                 )}
//               </div>
//             </div>
//           </div>
//           <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
//         </div>
//       ) : null}
//     </div>
//   );
// };

// export default BookingSteps4;



/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  MdKeyboardArrowDown,
  MdKeyboardArrowUp,
  MdOutlinePayments,
} from "react-icons/md";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  CheckTicketOrder,
  CreateTicketOrder, 
} from "../API/EventApi";
import { FaAddressCard, FaDollarSign, FaMinus, FaRupeeSign } from "react-icons/fa";
import lmsicon from "../../Images/lms-icon-white.png";
import { useFormik } from "formik";
import { IoIosInformationCircleOutline } from "react-icons/io";
import * as yup from "yup";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import { RxCross2 } from "react-icons/rx";
import Config from "../API/Config";
import BeatLoader from "react-spinners/BeatLoader";
import { toast } from "react-toastify";
import FormLoader from "../common/FormLoader";
import { FaCalendar, FaCalendarCheck, FaLocationDot } from "react-icons/fa6";
import ScrollToTop from "../ScrollToTop";

const phoneLengthByCountry = {
  India: 10, // India
  Australia: 10, // Australia
  UK: 10, // UK
  USA: 10, // USA
  Canada: 10, // Canada
  // Add other countries and their rules here
};
const getPhoneValidationSchema = (countryCode) => {
  const phoneLength = phoneLengthByCountry[countryCode] || 10;
  return yup
    .string()
    .required(`Phone number is required for ${countryCode}`)
    .test(
      "is-valid-length",
      `Enter a valid phone number of length ${phoneLength}`,
      (value) => value && value.replace(/\D/g, "").length === phoneLength
    );
};
const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const BookingSteps4 = () => {
  const getCountrySlug = Config.getCountrySlug();
  const validationSchema = yup.object().shape({
    EOP: yup.string().when("Country", {
      is: (value) => !!value, // Ensure Country is selected
      then: (value) => getPhoneValidationSchema(getCountrySlug),
      otherwise: yup.string().required("Phone number is required"),
    }),
  });
  usePageSEO(
    "Love My Show ", // title
    "Love My Show ", // description
    ["Love My Show  "] // keywords
  );
  const UserId = Config.getId();
  const UserType = Config.getType();

  const navigate = useNavigate();
  const location = useLocation();
  const bookingDetails = location.state;
  const [EventAddressData, setEventAddressData] = useState([]); 
  const [appliedCouponAmount, setAppliedCouponAmount] = useState(0);
  const [CouponId, setCouponId] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [appliedCouponPercentage, setAppliedCouponPercentage] = useState(0);
 
  const { EventId, AddressId, DateId, TicketId } = useParams();
  const [coupan, setcoupan] = useState([]);
  useEffect(() => {
    const fetchEventAddressData = async () => {
      try {
        setEventAddressData(bookingDetails);
        const ticketQty = bookingDetails?.total?.Qty;
        formik3.setFieldValue("Qty", ticketQty); // Set Qty in formik
        setcoupan(bookingDetails?.Coupon);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchEventAddressData();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const formik3 = useFormik({
    initialValues: {
      UserId: UserId,
      EventId: EventId,
      AddressId: AddressId,
      DateId: DateId,
      TicketId: TicketId,
      CouponId: CouponId,
      Qty: "",
    },
    onSubmit: async (values) => {
      setIsFormLoading(true);

      try {
        // Step 1: Check ticket availability
        const checkResponse = await CheckTicketOrder({
          TicketId: values.TicketId,
          Qty: values.Qty,
        });

        if (checkResponse.status === true) {
          const createResponse = await CreateTicketOrder(values);
          if (createResponse.status === true) {
            window.location.href = createResponse.redirectUrl;
          } else {
            navigate(`/event/booking-steps/${EventId}/${AddressId}/${DateId}`);
          }
        } else {
          // Redirect to home page if tickets are not available
          navigate(`/home`);
        }
      } catch (error) {
        console.error("Error:", error);
        // Optionally, handle errors here (e.g., show an alert or error message)
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  // Convert EventStartDateTime to a Date object
  const startDate = EventAddressData?.date?.EventDate;
 
console.log('====================================');
console.log(startDate);
console.log('====================================');
  // --------------------Login-----------------
  const [loginpopup, setloginpopup] = useState(false);
  const handleProfileClick = () => {
    setloginpopup(true);
  };
  const [isMobileScreen, setIsMobileScreen] = useState(true);
  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  // -----------------------otpsend-------------------------
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Country: getCountrySlug,
      Type: "U",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendOTP(values);
        if (response.status === true) {
          setIsMobileScreen(false);
        }
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Country: getCountrySlug,
      Type: "U",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          window.location.reload();
          setloginpopup(false);
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Country: getCountrySlug,
    Type: "U",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  const handleLoginClick = () => {
    toast.info("To book a ticket, you must be registered as a user.", {
      autoClose: 2000,
      onClose: () => {
        setloginpopup(true);
      },
    });
  };

  const [loading, setLoading] = useState(true); // Loading state 
  
  // Modify your coupon application logic
const applyCouponCode = () => {
  const selectedCoupon = coupan.find(coupon => coupon.Code === couponCode);
  if (selectedCoupon) {
      const appliedPercentage = selectedCoupon.Amount; // Assuming the coupon has a Percentage field
      const appliedId = selectedCoupon.Id; // Assuming the coupon has an Id field

      setAppliedCouponPercentage(appliedPercentage);
      setCouponId(appliedId);

      // Save coupon details to localStorage
      localStorage.setItem("couponCode", couponCode);
      localStorage.setItem("appliedCouponPercentage", appliedPercentage);
      localStorage.setItem("couponId", appliedId);

      formik3.setFieldValue("CouponId", appliedId); // Set CouponId in formik
  }
};

// Adjust the calculateTotalAmount function to handle percentage discount
const calculateTotalAmount = () => {
  const subtotal = EventAddressData?.total?.subTotal || 0;
  const charge = EventAddressData?.total?.charge || 0;
  const discountPercentage = appliedCouponPercentage || 0;
  const discountAmount = (subtotal * discountPercentage) / 100; // Calculate discount based on percentage
  return subtotal + charge - discountAmount;
};



const calculateCouponAmount = () => {
  const subtotal = EventAddressData?.total?.subTotal || 0; 
  const discountPercentage = appliedCouponPercentage || 0;

  const discountAmount = (subtotal * discountPercentage) / 100; // Calculate discount based on percentage
  return discountAmount;
};



// Restore coupon from localStorage on component mount
useEffect(() => {
  const savedCouponCode = localStorage.getItem("couponCode");
  const savedCouponPercentage = localStorage.getItem("appliedCouponPercentage");
  const savedCouponId = localStorage.getItem("couponId");

  if (savedCouponCode) setCouponCode(savedCouponCode);
  if (savedCouponPercentage) setAppliedCouponPercentage(Number(savedCouponPercentage));
  if (savedCouponId) setCouponId(Number(savedCouponId));
}, []);

// Remove coupon when navigating to a new page
useEffect(() => {
  const handleNavigation = () => {
      // Clear the coupon from localStorage when navigating to another page
      localStorage.removeItem("couponCode");
      localStorage.removeItem("appliedCouponPercentage");
      localStorage.removeItem("couponId");

      setAppliedCouponPercentage(0);
      setCouponId(0);
      setCouponCode("");
  };

  // Call handleNavigation when location changes (indicating navigation)
  handleNavigation();
}, [location]); // Run this effect when the location changes

const removeCoupon = () => {
  setAppliedCouponPercentage(0);
  setCouponId(0);
  setCouponCode("");

  // Remove coupon details from localStorage
  localStorage.removeItem("couponCode");
  localStorage.removeItem("appliedCouponPercentage");
  localStorage.removeItem("couponId");

  formik3.setFieldValue("CouponId", 0); // Reset coupon in formik
};

  usePageSEO(
    "Love My Show | Book Ticket", // title
    "Love My Show | Book Ticket", // description
    ["Love My Show | Book Ticket"] // keywords
  );
  return (
    <div className="py-5 container mx-auto ">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full py-4 px-4">
        <form onSubmit={formik3.handleSubmit}>
          <div className="relative flex items-center justify-between md:w-1/2 mx-auto">
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r from-themecolor1 to-themecolor2"></div>
            <div className="absolute left-0 top-2/4 h-0.5 w-full -translate-y-2/4 bg-gradient-to-r bg-gray-300  transition-all duration-500"></div>
            <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
              <img src={lmsicon} alt="" className="w-6 h-6" />
            </div>
            <div className="relative grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
              <FaCalendar className="w-5 h-5" />
            </div>
            <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
              <FaAddressCard className="w-5 h-5" />
            </div>
            <div className="relative  grid w-10 h-10 font-bold text-white transition-all duration-300 bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-full place-items-center">
              <MdOutlinePayments className="w-5 h-5" />
            </div>
          </div>
          <div className="my-10">
            <div className="dark:bg-boxdark-2 dark:text-white  ">
              <div className="container mx-auto ">
                <div className="grid grid-cols-1 lg:grid-cols-3 dark:bg-boxdark-2 dark:text-white  ">
                  <div className="col-span-2 bg-gray-100 dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600 px-5">
                    <h1 className="py-4 text-gray-500 dark:text-white md:text-normal text-sm font-medium">
                      Please select from the following option(s)
                    </h1>
                    <div>
                      <div>
                        <div className="flex mb-2">
                          <input
                            type="radio"
                            name="PaymentMethod"
                            id="mTicket"
                            value="0"
                            checked
                            onChange={formik3.handleChange}
                          />
                          <label htmlFor="mTicket" className="ml-2 text-sm">
                            M-Ticket
                          </label>
                        </div>
                      </div>

                      <div className="bg-gray-200 dark:bg-boxdark-2 dark:text-white md:text-normal text-sm dark:border dark:border-zinc-600 p-3 mb-4">
                        <h1 className="font-semibold">M-Ticket Information</h1>
                        <p className="py-1 md:text-sm text-xs">
                          1. Customer(s) can access their tickets(s) from the
                          'My Profile' section on the app/mobile-web.
                        </p>
                        <p className="py-1 md:text-sm text-xs">
                          2. It is mandatory to present the ticket(s) in my
                          profile section via app/mobile-web at the venue.
                        </p>
                        <p className="py-1 md:text-sm text-xs">
                          3. No physical ticket(s) are required to enter the
                          venue
                        </p>
                      </div>
                    </div>

                    {formik3.touched.PaymentMethod &&
                    formik3.errors.PaymentMethod ? (
                      <div className="text-red-500 text-xs mt-2">
                        {formik3.errors.PaymentMethod}
                      </div>
                    ) : null}

                    {coupan.length > 0 ? (
                      <div className="my-4">
                        <label htmlFor="couponCode" className="mb-2 text-sm">
                          Coupon Code
                        </label>
                        <div className="mt-1">
                          <div className="flex w-full md:w-96">
                            <select
                              id="couponCode"
                              className="border dark:border-zinc-600 py-3 rounded-tl-lg rounded-bl-lg text-sm px-4 text-gray-500 w-full dark:bg-boxdark-2 dark:text-white"
                              name="couponCode"
                              onChange={(e) => setCouponCode(e.target.value)}
                              value={couponCode} // Set the selected coupon code
                              disabled={appliedCouponAmount > 0} // Disable when a coupon is applied
                            >
                              <option value="">Select Coupon</option>
                              {coupan.map((val, index) => (
                                <option key={index} value={val.Code}>
                                  {val.Code}
                                </option>
                              ))}
                            </select>
                            {calculateCouponAmount() > 0 ? (
                              <button
                                type="button"
                                className="bg-red-500 rounded-r-lg text-white px-5"
                                onClick={removeCoupon}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="bg-gradient-to-r from-themecolor1 to-themecolor2 rounded-r-lg text-white px-5"
                                onClick={applyCouponCode}
                              >
                                Apply
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className="bg-gray-100 lg:mx-5 pt-5 mt-0 py-5 dark:bg-boxdark-2 dark:text-white dark:border dark:border-zinc-600">
                    <div className="grid grid-cols-2 md:grid-cols-2 border border-gray-300 mx-2 rounded-md bg-white dark:bg-boxdark-2 mb-3 ">
                      <div className="text-black dark:text-white py-2 px-5 border-b dark:border-zinc-600">
                        <h1 className="md:text-normal text-sm font-semibold">
                          {EventAddressData?.event?.EventName}
                        </h1>
                        <p className="text-sm text-gray-500 dark:text-white">
                          <b className="text-xs">Ticket Qty: </b>
                          {EventAddressData?.total?.Qty}
                        </p>
                      </div>

                      <h1 className="py-2 pr-5 border-b text-themecolor1 dark:border-zinc-600 flex justify-end items-center">
                        {getCountrySlug === "India" ? (
                          <>
                            <span className="text-sm">
                              <FaRupeeSign />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-sm">
                              <FaDollarSign />
                            </span>
                          </>
                        )}{" "}
                        {EventAddressData?.total?.Price}
                      </h1>
                    </div>
                    <div className="grid grid-cols-1 md:grid-cols-1 border dark:bg-boxdark-2 border-gray-300 mx-2 rounded-md py-2 px-5 text-sm bg-white mb-3">
                      <div className="flex items-center">
                        <FaCalendarCheck className="text-xl mr-2" />
                        <div>
                          <p className="mt-2">
                            <b className="md:text-sm text-xs">Event Date:</b>
                          </p>
                          <p className=" pb-2 md:text-sm text-xs text-gray-500 dark:text-white">
                            {startDate}- (
                            {EventAddressData?.date?.EventStartTime} -{" "}
                            {EventAddressData?.date?.EventEndTime})
                          </p>
                        </div>
                      </div>
                      <div className="flex items-center">
                        <FaLocationDot className="text-xl mr-2" />
                        <div>
                          <p className="mt-2">
                            <b className="md:text-sm text-xs">Venue</b>
                          </p>
                          <p className=" pb-2 md:text-sm text-xs text-gray-500 dark:text-white text-justify">
                            {EventAddressData?.address?.Location} ,
                            {EventAddressData?.address?.CityTitle} ,
                            {EventAddressData?.address?.CountryTitle}
                          </p>
                        </div>
                      </div>
                      <h1 className="md:text-sm text-xs border-t dark:border-zinc-600 py-2">
                        {EventAddressData?.ticket?.TicketName} (
                        {EventAddressData?.total?.Price}) :{" "}
                        {EventAddressData?.total?.Qty} ticket(s)
                      </h1>
                    </div>
                    <div className="grid grid-cols-2 mt-4 pb-2">
                      <p className="px-5 md:text-normal text-sm">Sub Total</p>
                      <p className="flex justify-end px-5 md:text-normal text-sm items-center ">
                        {" "}
                        {getCountrySlug === "India" ? (
                          <>
                            <span className="text-sm">
                              <FaRupeeSign />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="text-sm">
                              <FaDollarSign />
                            </span>
                          </>
                        )}{" "}
                        {EventAddressData?.total?.subTotal}
                      </p>
                    </div>
               
                    
                    {calculateCouponAmount() === 0 ? (
                      ""
                    ) : (
                      <div className="grid grid-cols-2 my-2 ">
                        <p className="px-5  md:text-normal text-sm ">Coupon Discount</p>
                        <p className="flex justify-end px-5 items-center  md:text-normal text-sm ">
                            <FaMinus className="text-sm w-3" /> {""}{getCountrySlug === "India" ? (
                            <>
                              <span className="text-sm">
                                <FaRupeeSign />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="text-sm">
                                <FaDollarSign />
                              </span>
                            </>
                          )}{" "}
                         {calculateCouponAmount()}
                        </p>
                      </div>
                    )}
                    <div className="flex px-5 py-2 border-b border-t">
                      <h1 className="w-full md:text-normal text-sm">Total Amount</h1>
                      <h1 className="flex items-center md:text-normal text-sm">
                        {getCountrySlug === "India" ? (
                          <>
                            <span className="md:text-normal text-sm">
                              <FaRupeeSign />
                            </span>
                          </>
                        ) : (
                          <>
                            <span className="md:text-normal text-sm">
                              <FaDollarSign />
                            </span>
                          </>
                        )}{" "}
                        {calculateTotalAmount()}
                      </h1>
                    </div>

                    <h1 className="flex items-center md:text-xs text-[11px] px-2 text-gray-400 mt-5 border-b dark:border-zinc-600 pb-4">
                      <IoIosInformationCircleOutline className="text-2xl mx-2" />
                      By proceeding, I express my consent to complete this
                      transaction
                    </h1>

                    {formik3.isSubmitting ? (
                      <div style={{ position: "relative" }} className="mt-3">
                        <div className="flex justify-center items-center">
                          <BeatLoader
                            color={"#DC3558"}
                            loading={loading}
                            size={18}
                          />
                        </div>
                        <div
                          style={{
                            position: "absolute",
                            bottom: "0",
                            left: "50%",
                            transform: "translateX(-50%)",
                          }}
                        ></div>
                      </div>
                    ) : (
                      <div>
                        {formik3.values.UserId === null ? (
                          <button
                            type="button"
                            onClick={() => handleProfileClick(true)}
                            className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
                          >
                            Login To Proceed
                          </button>
                        ) : UserType === "V" ? (
                          <button
                            type="button"
                            onClick={() => handleLoginClick()}
                            className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
                          >
                            Login To Proceed
                          </button>
                        ) : (
                          <button
                            type="submit"
                            className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor1 to-themecolor2 text-white shadow-lg hover:scale-105 transition-all mt-6"
                          >
                            Pay
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-between mt-10">
            <div>
              <Link
                to={`/event/booking-steps/${EventId}/${AddressId}/${DateId}`}
                className="my-2 py-2 px-10 rounded-full md:text-normal text-sm flex mx-auto bg-gradient-to-r from-themecolor2 to-themecolor1 text-white shadow-lg hover:scale-105 transition-all"
              >
                Previous
              </Link>
            </div>
          </div>
        </form>
      </div>
      {loginpopup ? (
        <div>
          <div className="px-3 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative h-80 flex flex-col w-full bg-white  outline-none dark:bg-boxdark-2 dark:text-white   focus:outline-none">
                <div className="flex items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t dark:border-zinc-600">
                  <h3 className="text-xl  block font-semibold">Login</h3>
                  <button
                    className="text-themecolor1 background-transparent font-bold uppercase  text-3xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setloginpopup(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                {isMobileScreen ? (
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <p className="text-center text-xl mt-2">
                        Enter Mobile No
                      </p>
                      <div className="p-6 pt-2 border-solid border-blueGray-200 pb-6 rounded-b">
                        <div className="flex border-0 w-full">
                          <input
                            type="text"
                            name="EOP"
                            autoFocus
                            value={formik.values.EOP}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={phoneLengthByCountry[getCountrySlug] || 10}
                            className="border dark:border-zinc-600 outline-none w-full p-2 rounded-md dark:bg-boxdark-2 dark:text-white"
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                        {formik.touched.EOP && formik.errors.EOP ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.EOP}
                          </div>
                        ) : null}
                      </div>
                      <div className="px-6">
                        <button
                          className="bg-themecolor1 p-2 w-full text-white rounded-md"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                      <p className="text-xs block px-6 m-auto text-gray-500 py-5 dark:text-white">
                        I agree to the{" "}
                        <Link
                          to={"/page/terms-and-conditions"}
                          className="underline decoration-1"
                        >
                          Terms & conditions
                        </Link>{" "}
                        &{" "}
                        <Link
                          to={"/page/privacy-policy"}
                          className="underline decoration-1"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </form>
                  </div>
                ) : (
                  <div className="my-auto">
                    <form onSubmit={formik2.handleSubmit}>
                      <p className="text-center text-xl">Enter OTP</p>
                      <div className="p-3  w-full flex justify-center">
                        <input
                          type="text"
                          placeholder="****"
                          name="OTP"
                          autoFocus
                          maxLength={4}
                          value={formik2.values.OTP}
                          onChange={formik2.handleChange}
                          className="border text-center w-full dark:bg-boxdark-2 dark:text-white py-3  mx-2 tracking-wide"
                        />
                      </div>
                      {formik2.touched.OTP && formik2.errors.OTP ? (
                        <div className="text-red-500 text-xs mt-2 px-5">
                          {formik2.errors.OTP}
                        </div>
                      ) : null}
                      <div className="text-center text-xs mt-3">
                        {showResend ? (
                          <>
                            OTP expired?{" "}
                            <button
                              onClick={handleResendOTP}
                              type="button"
                              className="underline text-themecolor1"
                            >
                              Resend OTP
                            </button>
                          </>
                        ) : (
                          `Resend OTP in ${formatTimer()}`
                        )}
                      </div>
                      <div className="grid grid-cols-2 mt-7">
                        <button
                          className="bg-themecolor1 mx-5 p-2 text-white rounded-md"
                          onClick={() => setIsMobileScreen(true)}
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="bg-themecolor1 mx-5 p-2 text-white text-center rounded-md"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </div>
  );
};

export default BookingSteps4;
