import React, { useEffect, useRef, useState } from "react";
import { FaImage } from "react-icons/fa";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getAllArtist } from "../API/ArtistApi";
import { getAllCategory } from "../API/CategoryApi";
import DashboardMenu from "./DashboardMenu";
import Config from "../API/Config";
import { AddEvent } from "../API/EventApi";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import TinySetUp from "./TinySetUp";

const validationSchema = Yup.object().shape({
  EventName: Yup.string().required("Event name is required"),
  EventEmail: Yup.string().email().required("Email is required"),
  EventSlug: Yup.string().required("Event Slug is required"),
  EventOrganizer: Yup.string().required("EventOrganizer name is required"),
  EventDescription: Yup.string().required("Description is required"),
  EventStartDateTime: Yup.string().required(
    "Event Start date/time is required"
  ),
  EventCategoryId: Yup.string().required("Event Category is required"),
  EventArtistId: Yup.string().required("Artist is required"),
  AgeRestriction: Yup.string().required("Age Restriction is required"),
  HealthySafetyPrecautions: Yup.string().required(
    "Healthy Safety Precautions is required"
  ),
  PointOfContactName: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, "Name must only contain letters")
    .required("Contact person name is required"),
  PointOfContactPhone: Yup.string()
    .matches(/^[0-9]+$/, "Phone number must only contain digits")
    .required("Contact person number is required"),
  PointOfContactEmail: Yup.string()
    .email()
    .required("Contact person email is required"),
  Banner: Yup.mixed()
    .test("required", "Banner File is Required", function (value) {
      if (this.parent.Banner || value) return true;
      return false;
    })
    .test("fileSize", "Banner must be less than 1MB", (value) => {
      if (!value) return true;
      return value && value.size <= 1024 * 1024;
    }),
  Thumb: Yup.mixed()
    .test("required", "Thumb File is Required", function (value) {
      if (this.parent.Thumb || value) return true;
      return false;
    })
    .test("fileSize", "Thumb Image must be less than 1MB", function (value) {
      if (!value) return true;
      return value && value.size <= 1024 * 1024;
    }),
});
const ListShow = () => {
  const CountryTitle = Config.getCountrySlug();
  const CountryId = Config.getCountryId();
  const Navigate = useNavigate();
  const KYCStatus = Config.getKYCStatus();
  const Id = Config.getId();
  const InitialFormData = {
    UserId: Id,
    EventName: "",
    EventSlug: "",
    EventEmail: "",
    EventOrganizer: "",
    EventDescription: "",
    AgeRestriction: "",
    Banner: "",
    Thumb: "",
    EventStartDateTime: "",
    EventCategoryId: "",
    EventArtistId: "",
    HealthySafetyPrecautions: "",
    PointOfContactName: "",
    PointOfContactPhone: "",
    PointOfContactEmail: "",
    Country: CountryTitle,
    CountryId: CountryId,
  };
  usePageSEO(
    "Love My Show | List Show", // title
    "Love My Show | List Show", // description
    ["Love My Show | List Show "] // keywords
  );
  // ==================Artist Data===============
  const [ArtistData, setArtistData] = useState(null);
  const fetchArtistData = async () => {
    try {
      const result = await getAllArtist();
      setArtistData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  // ==================Category Data===============
  const [CategoryData, setCategoryData] = useState(null);
  const fetchCategoryData = async () => {
    try {
      const result = await getAllCategory();
      setCategoryData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchArtistData();
    fetchCategoryData();
  }, []);

  const [formData, setFormData] = useState(InitialFormData);
  const [errors, setErrors] = useState({});
  const fieldRefs = {
    EventName: useRef(null),
    EventSlug: useRef(null),
    EventEmail: useRef(null),
    EventOrganizer: useRef(null),
    companyname: useRef(null),
    type: useRef(null),
    EventDescription: useRef(null),
    Banner: useRef(null),
    Thumb: useRef(null),
    EventStartDateTime: useRef(null),
    EventCategoryId: useRef(null),
    EventArtistId: useRef(null),
    PointOfContactName: useRef(null),
    PointOfContactPhone: useRef(null),
    PointOfContactEmail: useRef(null),
    AgeRestriction: useRef(null),
    HealthySafetyPrecautions: useRef(null),
    Country: useRef(null),
    CountryId: useRef(null),
  };
  const getMinDateTime = () => {
    const now = new Date();
    const minDateTime = new Date(
      now.getTime() - now.getTimezoneOffset() * 60000 + 24 * 60 * 60 * 1000
    );
    const minDateTimeString = minDateTime.toISOString().slice(0, 16);
    return minDateTimeString;
  };

  const handleInputChange2 = (e) => {
    const { name, value } = e.target;

    if (name === "EventSlug") {
      let newSlug = value
        .toLowerCase()
        .trim()
        .replace(/\s+/g, "-")
        .replace(/\//g, "-")
        .replace(/%/g, "-")
        .replace(/&/g, "-")
        .replace(/\?/g, "-");

      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
        EventSlug: newSlug,
      }));
    } else {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
  };

  const handleFileInputChange = (e) => {
    const fieldName = e.target.name;
    const file = e.target.files[0];
    setFormData((prevFormData) => ({
      ...prevFormData,
      [fieldName]: file,
    }));
  };

  const [isFormLoading, setIsFormLoading] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const formDataToSend = new FormData();

      Object.keys(formData).forEach((key) => {
        if (key === "AddressData") {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else if (key === "Ticketdata") {
          formDataToSend.append(key, JSON.stringify(formData[key]));
        } else {
          formDataToSend.append(key, formData[key]);
        }
      });
      const data = await AddEvent(formDataToSend);

      if (data.status === true) {
        const eventId = data.lastInsertedId;
        Navigate(`/list-show/add-address/${eventId}`);
        setFormData({
          UserId: Id,
          EventName: "",
          EventSlug: "",
          EventEmail: "",
          EventOrganizer: "",
          EventDescription: "",
          AgeRestriction: "",
          Banner: "",
          Thumb: "",
          EventStartDateTime: "",
          EventCategoryId: "",
          EventArtistId: "",
          HealthySafetyPrecautions: "",
          PointOfContactName: "",
          PointOfContactPhone: "",
          PointOfContactEmail: "",
        });
      }
    } catch (error) {
      if (error.name === "ValidationError") {
        const validationErrors = {};
        error.inner.forEach((err) => {
          validationErrors[err.path] = err.message;
        });

        setErrors(validationErrors);
        for (const fieldName in validationErrors) {
          const fieldRef = fieldRefs[fieldName];
          if (fieldRef && fieldRef.current) {
            fieldRef.current.scrollIntoView({ behavior: "smooth" });
            break;
          }
        }
      } else {
        console.error("Error:", error);
      }
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  return (
    <div className=" dark:bg-boxdark-2 dark:text-white">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <div className="w-full container mx-auto">
        <div className="w-full  lg:grid grid-cols-5 pb-6">
          <DashboardMenu />

          {/* ===============================Nav Pilss data ====================================*/}
          <div className="relative flex h-full flex-col min-w-0 break-words  w-full rounded col-span-4 py-3">
            <div className=" flex-auto">
              <div className=" px-4">
                <div className="bg-white border dark:border-gray-400 rounded-md  p-4 drop-shadow-md  dark:bg-boxdark-2 dark:text-white">
                  <div className="border-b flex items-center bg-themecolor1 py-2 mb-3">
                    <h1 className="text-lg text-center text-white mx-auto py-1 font-bold pt-0">
                      List Show
                    </h1>
                  </div>
                  {KYCStatus === 0 ? (
                    <div className="px-3 border pb-4">
                      <div className="bg-yellow-100  py-2 text-base px-6 my-4  w-full text-center rounded-lg">
                      Please, Complate Your KYC 
                      </div>
                      <p className="pb-3">
                        Please, Complate Your KYC Before Listing
                      </p>
                      <Link
                        to={"/kyc"}
                        className="ml-auto px-4 py-2 rounded bg-themecolor1 text-white"
                      >
                        Edit
                      </Link>
                    </div>
                  ) : (
                    <form onSubmit={handleSubmit}>
                      <div className="container mx-auto ">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Event Name <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              name="EventName"
                              value={formData.EventName}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.EventName ? "border-red-600" : ""
                              }`}
                              placeholder="Event Name"
                              ref={fieldRefs.EventName}
                            />
                            {errors.EventName && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventName}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Event Slug <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              name="EventSlug"
                              value={formData.EventSlug}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.EventSlug ? "border-red-600" : ""
                              }`}
                              placeholder="Event Slug"
                              ref={fieldRefs.EventSlug}
                            />
                            {errors.EventSlug && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventSlug}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Email <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="email"
                              name="EventEmail"
                              value={formData.EventEmail}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.EventEmail ? "border-red-600" : ""
                              }`}
                              placeholder="Email"
                              ref={fieldRefs.EventEmail}
                            />
                            {errors.EventEmail && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventEmail}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Your event's EventOrganizer{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              name="EventOrganizer"
                              value={formData.EventOrganizer}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.EventOrganizer ? "border-red-600" : ""
                              }`}
                              placeholder="Your event's EventOrganizer"
                              ref={fieldRefs.EventOrganizer}
                            />
                            {errors.EventOrganizer && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventOrganizer}
                              </div>
                            )}
                          </div>
                        </div>

                        <h1 className="my-3 text-sm">
                          Event Description{" "}
                          <span className="text-red-600">*</span>
                        </h1>
                        <TinySetUp
                          value={formData.EventDescription}
                          onChange={(value) =>
                            setFormData((prev) => ({
                              ...prev,
                              EventDescription: value,
                            }))
                          }
                          onBlur={() =>
                            handleInputChange2({
                              target: {
                                name: "EventDescription",
                                value: formData.EventDescription,
                              },
                            })
                          }
                        />
                        {errors.EventDescription && (
                          <div className="text-red-600 text-sm mt-1">
                            {errors.EventDescription}
                          </div>
                        )}

                        {/* =========Add Images and Add Videos =================*/}
                        <div className="mt-4">
                          <h1 className="text-lg px-3 py-2  text-white font-normal bg-black mb-2">
                            Image Section
                          </h1>
                          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="py-3">
                              <h1 className="mb-2 text-sm">
                                Add Banner Image{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <p className="text-xs text-gray-500 dark:text-white pb-2">
                                (.jpg, .jpeg, .png, .webp)
                              </p>
                              <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4 cursor-pointer mb-3">
                                <h1 className="text-3xl">
                                  <FaImage className="block m-auto" />
                                </h1>
                                <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                                  Select File here (below 1 MB)(1200 × 450 px)
                                </h1>
                                <input
                                  type="file"
                                  name="Banner"
                                  onChange={handleFileInputChange}
                                  className={`block w-56 lg:w-80 foldsmall:w-40 text-sm text-slate-500  dark:bg-boxdark-2 dark:text-white m-auto my-5 border  border-[#F84464] bg-[#f7f7f7] rounded-lg
         file:mr-4 file:py-3 file:px-4
         file:border-0 file:text-sm file:font-semibold
         file:bg-[#EC5E71] file:text-white
         cursor-pointer ${errors.Banner ? "border-red-600" : ""}`}
                                  ref={fieldRefs.Banner}
                                />
                                {errors.Banner && (
                                  <div className="text-red-600 text-sm mt-1">
                                    {errors.Banner}
                                  </div>
                                )}
                              </div>
                            </div>

                            <div className="py-3">
                              <h1 className="mb-2 text-sm">
                                Add Thumb Image{" "}
                                <span className="text-red-600">*</span>
                              </h1>
                              <p className="text-xs text-gray-500 dark:text-white pb-2">
                                (.jpg, .jpeg, .png, .webp)
                              </p>
                              <div className="border-2 border-dotted border-gray-600 rounded-md text-center pt-7 py-4 cursor-pointer mb-3">
                                <h1 className="text-3xl">
                                  <FaImage className="block m-auto" />
                                </h1>
                                <h1 className="px-4 lg:px-0 text-gray-600 dark:text-white text-xs pt-3">
                                  Select File here (below 1 MB)(270 × 325 px)
                                </h1>
                                <input
                                  type="file"
                                  name="Thumb"
                                  onChange={handleFileInputChange}
                                  className={`block w-56 lg:w-80 foldsmall:w-40 text-sm text-slate-500  dark:bg-boxdark-2 dark:text-white m-auto my-5 border  border-[#F84464] bg-[#f7f7f7] rounded-lg
         file:mr-4 file:py-3 file:px-4
         file:border-0 file:text-sm file:font-semibold
         file:bg-[#EC5E71] file:text-white
         cursor-pointer ${errors.Thumb ? "border-red-600" : ""}`}
                                  ref={fieldRefs.Thumb}
                                />
                                {errors.Thumb && (
                                  <div className="text-red-600 text-sm mt-1">
                                    {errors.Thumb}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2  gap-4">
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Event Booking Start Date{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="datetime-local"
                              name="EventStartDateTime"
                              value={formData.EventStartDateTime}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.EventStartDateTime
                                  ? "border-red-600"
                                  : ""
                              }`}
                              ref={fieldRefs.EventStartDateTime}
                              min={getMinDateTime()}
                            />
                            {errors.EventStartDateTime && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventStartDateTime}
                              </div>
                            )}
                          </div>

                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Select Category{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="EventCategoryId"
                              value={formData.EventCategoryId}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white form-select ${
                                errors.EventCategoryId ? "border-red-600" : ""
                              }`}
                              ref={fieldRefs.EventCategoryId}
                            >
                              <option value="">Select Category</option>
                              {CategoryData?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.Title}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.EventCategoryId && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventCategoryId}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Select Artist{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <select
                              name="EventArtistId"
                              value={formData.EventArtistId}
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  form-select ${
                                errors.EventArtistId ? "border-red-600" : ""
                              }`}
                              ref={fieldRefs.EventArtistId}
                            >
                              <option>Select Artist</option>
                              {ArtistData?.map((val, index) => {
                                return (
                                  <option value={val.Id} key={index}>
                                    {val.Title}
                                  </option>
                                );
                              })}
                            </select>
                            {errors.EventArtistId && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.EventArtistId}
                              </div>
                            )}
                          </div>
                          <div className="">
                            <h1 className="mb-2 text-sm">
                              Age Restriction{" "}
                              <span className="text-red-600">*</span>
                            </h1>
                            <input
                              type="text"
                              placeholder="Age Restriction"
                              value={formData.AgeRestriction}
                              name="AgeRestriction"
                              onChange={handleInputChange2}
                              className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white  ${
                                errors.AgeRestriction ? "border-red-600" : ""
                              }`}
                              ref={fieldRefs.AgeRestriction}
                            />
                            {errors.AgeRestriction && (
                              <div className="text-red-600 text-sm mt-1">
                                {errors.AgeRestriction}
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="mt-4">
                          <h1 className="text-lg px-3 py-2  text-white font-normal bg-black mb-4">
                            Point Of Contact
                          </h1>
                          <p className="text-sm mb-4">
                            Details of the person managing the event from your
                            end, it could be your or someone on your team. In
                            case we need to get in touch, we know how to reach
                            you.
                          </p>
                          {/*=================== Details =========*/}
                          <div className="grid grid-cols-1 md:grid-cols-3  gap-4 my-2">
                            <div className="">
                              <input
                                type="text"
                                name="PointOfContactName"
                                value={formData.PointOfContactName}
                                onChange={handleInputChange2}
                                placeholder="Name"
                                className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                                  errors.PointOfContactName
                                    ? "border-red-600"
                                    : ""
                                }`}
                                ref={fieldRefs.PointOfContactName}
                              />
                              {errors.PointOfContactName && (
                                <div className="text-red-600 text-sm mt-1">
                                  {errors.PointOfContactName}
                                </div>
                              )}
                            </div>

                            <div className="">
                              <input
                                type="email"
                                name="PointOfContactEmail"
                                value={formData.PointOfContactEmail}
                                onChange={handleInputChange2}
                                placeholder="Email"
                                className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                                  errors.PointOfContactEmail
                                    ? "border-red-600"
                                    : ""
                                }`}
                                ref={fieldRefs.PointOfContactEmail}
                              />
                              {errors.PointOfContactEmail && (
                                <div className="text-red-600 text-sm mt-1">
                                  {errors.PointOfContactEmail}
                                </div>
                              )}
                            </div>
                            <div className="">
                              <input
                                type="text"
                                name="PointOfContactPhone"
                                value={formData.PointOfContactPhone}
                                onChange={handleInputChange2}
                                placeholder="Phone"
                                className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                                  errors.PointOfContactPhone
                                    ? "border-red-600"
                                    : ""
                                }`}
                                ref={fieldRefs.PointOfContactPhone}
                              />
                              {errors.PointOfContactPhone && (
                                <div className="text-red-600 text-sm mt-1">
                                  {errors.PointOfContactPhone}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* =====Healthy and Safety Precautions ===========*/}

                        <div className="">
                          <h1 className="mb-2 text-sm">
                            Healthy Safety Precautions{" "}
                            <span className="text-red-600">*</span>
                          </h1>
                          <select
                            name="HealthySafetyPrecautions"
                            value={formData.HealthySafetyPrecautions}
                            onChange={handleInputChange2}
                            className={`border dark:border-zinc-600 py-3 rounded-md text-sm px-4 text-gray-500 w-full  dark:bg-boxdark-2 dark:text-white ${
                              errors.HealthySafetyPrecautions
                                ? "border-red-600"
                                : ""
                            }`}
                            ref={fieldRefs.HealthySafetyPrecautions}
                          >
                            <option value="">
                              Select Healthy Safety Precautions
                            </option>
                            <option value="HealthySafetyPrecautions">
                              Healthy Safety Precautions
                            </option>
                          </select>
                          {errors.HealthySafetyPrecautions && (
                            <div className="text-red-600 text-sm mt-1">
                              {errors.HealthySafetyPrecautions}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex foldsmall:flex-col">
                        <button
                          type="submit"
                          className="bg-[#EC5E71] block my-5 foldsmall:my-2 text-white font-semibold px-12 p-2 rounded-md mx-auto foldsmall:mx-auto"
                        >
                          Submit & Continue
                        </button>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListShow;
