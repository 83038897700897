import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getArtistById } from "../API/ArtistApi";
import usePageSEO from "../hoosk/usepageSEO/Index";
import ClipLoader from "react-spinners/ClipLoader";
import ScrollToTop from "../ScrollToTop";

const Artistdetail = () => {
  usePageSEO(
    "Love My Show | Artist", // title
    "Explore the profile of your favorite artist on Love My Show", // description
    ["Love My Show", "Artist", "Profile"] // keywords
  );

  const { Slug } = useParams();
  const [artistData, setArtistData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getArtistById(Slug);
        setArtistData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [Slug]);

  usePageSEO(
    artistData ? `Love My Show | ${artistData.Title}` : "Love My Show | Artist",
    artistData
      ? `Explore the profile of ${artistData.Title} on Love My Show`
      : "Love My Show | Artist Profile",
    [artistData ? artistData.Title : "Love My Show | Artist"]
  );

  return (
    <div className="dark:bg-gray-900 dark:text-white">
      <ScrollToTop />
      {loading ? (
        <div className="flex justify-center items-center h-screen">
          <ClipLoader color={"#DC3558"} loading={loading} size={50} />
        </div>
      ) : (
        <div className="container mx-auto px-4 py-12">
          {/* Hero Profile Section */}
          <div className="relative bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden">
            <img
              src={artistData.Image}
              alt={artistData.Title}
              className="w-full h-80 object-cover bg-black opacity-5"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent flex items-center justify-center">
              <div className="text-center text-white p-6">
                <div className="relative">
                  <img
                    src={artistData.Image}
                    alt={artistData.Title}
                    className="w-32 h-32 rounded-full border-4 border-white absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                  />
                </div>
                <h1 className="lg:text-4xl md:text-3xl text-xl font-bold mt-24">{artistData.Title}</h1>
                <p className="text-lg mt-2">Occupation: Actor</p>
              </div>
            </div>
          </div>

          {/* About Section */}
          <div className="mt-8 bg-white dark:bg-gray-800 rounded-xl shadow-lg p-8">
            <h2 className="text-3xl font-semibold text-gray-900 dark:text-gray-100 mb-4">
              About
            </h2>
            <p
              className="text-justify text-gray-800 dark:text-gray-300"
              dangerouslySetInnerHTML={{ __html: artistData.Content }}
            ></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Artistdetail;
