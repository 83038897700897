import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react"; 
import MainLogo from "../../Images/newlogo.png";
import { RxCross2 } from "react-icons/rx";
import { MdAssessment } from "react-icons/md";
import { IoMdHome } from "react-icons/io";
import { MdEventAvailable } from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import { AiOutlineClose } from "react-icons/ai";
import { IoBagOutline } from "react-icons/io5";
import { BiMessageRoundedDots, BiSolidDetail } from "react-icons/bi";
import { IoSettingsOutline } from "react-icons/io5";
import {
  FaBars, 
  FaListAlt,
  FaMobileAlt,
  FaQuestionCircle,
  FaRegUser,
  FaSearch,
} from "react-icons/fa";
import { FaAngleRight, FaLocationDot, FaX } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import "react-phone-input-2/lib/bootstrap.css";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import { useNavigate } from "react-router-dom";
import Config from "../API/Config";

import { getAllCategory } from "../API/CategoryApi";

import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { getHomeEventByCityId } from "../API/EventApi";
import NoDataFound from "../common/NodataFound";

import lmsiconb from "../../Images/lms-icon-black.png";
import lmsiconw from "../../Images/lms-icon-white.png";
import lmsicong from "../../Images/lms-icon-gray.png"; 

const phoneLengthByCountry = {
  India: 10, // India
  Australia: 10, // Australia
  UK: 10, // UK
  USA: 10, // USA
  Canada: 10, // Canada
  // Add other countries and their rules here
};
const getPhoneValidationSchema = (countryCode) => {
  const phoneLength = phoneLengthByCountry[countryCode] || 10;
  return yup
    .string()
    .required(`Phone number is required for ${countryCode}`)
    .test(
      "is-valid-length",
      `Enter a valid phone number of length ${phoneLength}`,
      (value) => value && value.replace(/\D/g, "").length === phoneLength
    );
};

const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});
const Navbar = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  const UserData = Config.getUserData();
  const UserType = Config.getType();
  const getCountryFlagData = Config.getCountryFlag();
  const getCountrySlug = Config.getCountrySlug();
  const validationSchema = yup.object().shape({
    EOP: yup.string().when("Country", {
      is: (value) => !!value, // Ensure Country is selected
      then: (value) => getPhoneValidationSchema(getCountrySlug),
      otherwise: yup.string().required("Phone number is required"),
    }),
  });

  // ==================Country Data===============

  const CityId = Config.getCityIdData();
  const [TypeCategoryEventData, setTypeCategoryEventData] = useState(null);
  useEffect(() => {
    const fetchHomeEventByType = async () => {
      try {
        const result = await getHomeEventByCityId(CityId);
        setTypeCategoryEventData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };
    fetchHomeEventByType();
  }, [CityId]);

  const [showModal, setShowModal] = React.useState(false);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [viewAllCt, setViewAllCt] = useState(false);

  useEffect(() => {
    localStorage.setItem("CountryId", 3);
    localStorage.setItem(
      "CountryFlag",
      "https://www.bme.seawindsolution.ae/uploads//country/image/1718882486856-Flag_of_India.svg.webp"
    );
    localStorage.setItem("CountryTitle", "India");
    localStorage.setItem("CountrySlug", "India");
    localStorage.setItem("selectedCityId", 7);
    localStorage.setItem("selectedCitySlug", "Ahmedabad");
    localStorage.setItem("selectedCityTitle", "Ahmedabad");
  }, []);

  const SelectedCity = Config.getCitySlug();
  const SelectedCityName = Config.getCityTitle();

  const [CategoryData, setCategoryData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getAllCategory();
        setCategoryData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const bottomNavdata = [
    { id: "1", link: "/event", label: "Home", icons: <IoMdHome /> },
    {
      id: "2",
      link: "/event",
      label: "Event",
      icons: <MdEventAvailable />,
    },
    {
      id: "3",
      link: UserData && UserType === "V" ? "/list-show" : "/list-show-signin",
      label: "List Show",
      icons: "",
    },
  ];

  const profileLink = {
    id: "4",
    label: "Profile",
    label2: "Sign In",
    icons: <MdAccountCircle />,
    link: "/profile", // Define the static link here
  };

  const menuItemsForVendor = [
    {
      logo1: <MdAssessment />,
      logo2: <FaAngleRight />,
      text: "Dashboard",
      link: "/dashboard",
    },
    {
      logo1: <IoSettingsOutline />,
      logo2: <FaAngleRight />,
      text: "Accounts & Settings",
      link: "/profile",
    },
    {
      logo1: "",
      logo2: <FaAngleRight />,
      text: "List Your Show",
      link: "/list-show",
    },
    {
      logo1: <FaListAlt />,
      logo2: <FaAngleRight />,
      text: "Your Listing",
      link: "/your-listing",
    },
    // {
    //   logo1: <BiSolidOffer />,
    //   logo2: <FaAngleRight />,
    //   text: "Offers",
    //   link: "/offers",
    // },
    {
      logo1: <FaRegUser />,
      logo2: <FaAngleRight />,
      text: "Artist",
      link: "/artist",
    },
    {
      logo1: <BiSolidDetail />,
      logo2: <FaAngleRight />,
      text: "About",
      link: "/page/about",
    },
    {
      logo1: <FaMobileAlt />,
      logo2: <FaAngleRight />,
      text: "Contact",
      link: "/contact",
    },
    {
      logo1: <FaQuestionCircle />,
      logo2: <FaAngleRight />,
      text: "FAQ",
      link: "/faq",
    },
    {
      logo1: <BiMessageRoundedDots />,
      logo2: <FaAngleRight />,
      text: "Help & Support",
      link: "/page/help-support",
    },
  ];
  const menuItems = [
    {
      logo1: <IoSettingsOutline />,
      logo2: <FaAngleRight />,
      text: "Accounts & Settings",
      link: "/profile",
    },
    {
      logo1: <IoBagOutline />,
      logo2: <FaAngleRight />,
      text: "Your Booking",
      link: "/order-history",
    },
    {
      logo1: "",
      logo2: <FaAngleRight />,
      text: "List Your Show",
      link: "/list-show-signin",
    },
    // {
    //   logo1: <BiSolidOffer />,
    //   logo2: <FaAngleRight />,
    //   text: "Offers",
    //   link: "/offers",
    // },
    {
      logo1: <FaRegUser />,
      logo2: <FaAngleRight />,
      text: "Artist",
      link: "/artist",
    },
    {
      logo1: <BiSolidDetail />,
      logo2: <FaAngleRight />,
      text: "About",
      link: "/page/about",
    },
    {
      logo1: <FaMobileAlt />,
      logo2: <FaAngleRight />,
      text: "Contact",
      link: "/contact",
    },
    {
      logo1: <FaQuestionCircle />,
      logo2: <FaAngleRight />,
      text: "FAQ",
      link: "/faq",
    },
    {
      logo1: <BiMessageRoundedDots />,
      logo2: <FaAngleRight />,
      text: "Help & Support",
      link: "/page/help-support",
    },
  ];

  const menuItemsForWithoutLogin = [
    {
      logo1: "",
      logo2: <FaAngleRight />,
      text: "List Your Show",
      link: "/list-show-signin",
    },
    // {
    //   logo1: <BiSolidOffer />,
    //   logo2: <FaAngleRight />,
    //   text: "Offers",
    //   link: "/offers",
    // },
    {
      logo1: <BiSolidDetail />,
      logo2: <FaAngleRight />,
      text: "About",
      link: "/page/about",
    },
    {
      logo1: <FaMobileAlt />,
      logo2: <FaAngleRight />,
      text: "Contact",
      link: "/contact",
    },
    {
      logo1: <FaQuestionCircle />,
      logo2: <FaAngleRight />,
      text: "FAQ",
      link: "/faq",
    },
    {
      logo1: <BiMessageRoundedDots />,
      logo2: <FaAngleRight />,
      text: "Help & Support",
      link: "/page/help-support",
    },
  ];
  const [nav, setNav] = useState(false);

  const [loginpopup, setloginpopup] = useState(false);
  const handleProfileClick = () => {
    setloginpopup(true);
  };
  const [isMobileScreen, setIsMobileScreen] = useState(true);
  // -----------------------country code-------------------------
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Country: getCountrySlug,
      Type: "U",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendOTP(values);
        if (response.status === true) {
          setIsMobileScreen(false);
        }
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Country: getCountrySlug,
      Type: "U",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          navigate("/profile");
          window.location.reload();
          setloginpopup(false);
        } else {
          actions.resetForm();
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Country: getCountrySlug,
    Type: "U",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  // --------------------Logout-----------------
  const logout = () => {
    localStorage.removeItem("UserToken");
    localStorage.removeItem("UserData");
    navigate("/event");
    window.location.reload()
  };

  const [active, setActive] = useState("1");
  const clickedEvent = (id) => {
    setActive(id);
  };

  // ---------------------search=-------------------
  const [searchKeyword, setSearchKeyword] = useState("");

  const handleSearch = (event) => {
    event.preventDefault();
    if (searchKeyword.trim()) {
      navigate(`/search/${searchKeyword}`);
      setShowModal(false);
    }
  };
  return (
    <div className="">
      <ScrollToTop />
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      <section>
        {/* ===========================NavBar 1 ===============================*/}

        <div>
          <Disclosure as="nav" className="bg-white dark:bg-boxdark-2">
            {({ open }) => (
              <div>
                <div className="container mx-auto px-2 sm:px-4 lg:px-2">
                  <div className="relative flex h-16 items-center justify-between ">
                    <div className="absolute inset-y-0 left-0 flex items-center sm:hidden ml-[7px] md:ml-0">
                      {/* Mobile menu button*/}
                    </div>

                    {/* ================================Logo Part ===========================================*/}
                    <div className="flex  items-center justify-center   ">
                      <div className="dark:bg-white p-1 rounded">
                      <Link to={'/event'}>
                      
                      <img
                      className="h-6 w-auto"
                      src={MainLogo}
                      alt="Your Company"
                      />
                      </Link>
                      </div>
                    </div>

                    <div className="flex  items-center justify-center sm:items-stretch sm:justify-start">
                      <div className="hidden md:block">
                        <button className="border border-gray-300 dark:border-zinc-600 rounded-full relative ">
                          <input
                            type="text"
                            onClick={() => setShowModal(true)}
                            className="pl-10 py-3 px-4 rounded-full xl:w-[28rem] lg:w-[24rem] md:w-[22rem] surface:w-[22rem] dark:bg-boxdark-2 dark:text-white text-xs"
                            placeholder="Search Events"
                          />
                          <FaSearch className="absolute top-3 left-3 text-gray-500 font-semibold dark:text-white" />
                        </button>
                      </div>
                      <div className="hidden md:block">
                        <button className="border-themecolor1 dark:text-white border md:min-w-28 ml-5 rounded-full py-2 px-3 flex items-center text-sm text-gray-500">
                          {getCountryFlagData === null ? (
                            <>
                              <FaLocationDot className="mr-2 text-xl text-themecolor3" />{" "}
                            </>
                          ) : (
                            <>
                              <img
                                src={getCountryFlagData}
                                className="md:w-[20px] md:h-[20px] w-5 mr-2 rounded-full"
                                alt=""
                              />
                            </>
                          )}

                          <>{SelectedCityName} </>
                        </button>
                      </div>
                      <div className="absolute inset-y-0 right-0 lg:flex grid items-center  sm:static sm:inset-auto ">
                        {UserData ? (
                          <Link
                            to={"/profile"}
                            className="relative ml-4 rounded-md bg-[#F84464] py-2 px-4 md:px-4 text-sm text-white duration-200 hidden lg:block"
                          >
                            Profile
                          </Link>
                        ) : (
                          <button
                            type="button"
                            className="relative ml-4 rounded-md bg-[#F84464] py-2 px-4 md:px-4 text-sm text-white duration-200 hidden lg:block"
                            onClick={() => handleProfileClick(true)}
                          >
                            Sign In
                          </button>
                        )}

                        {/*======================= mobile Menu =======================*/}
                        <div className="max-w-[1640px] pl-5 ml-auto flex items-center">
                          <div className="flex">
                            <div
                              onClick={(e) => {
                                e.preventDefault();
                                setNav(!nav);
                              }}
                              className="cursor-pointer flex"
                            >
                              <FaBars
                                size={25}
                                className="dark:text-white text-gray-600 font-semibold"
                              />
                            </div>
                          </div>

                          {nav ? (
                            <div className="bg-black/80 fixed w-full h-screen z-10 top-0 left-0"></div>
                          ) : (
                            ""
                          )}
                          <div
                            className={
                              nav
                                ? "fixed top-0 right-0 w-[300px] foldsmall:w-[280px] h-screen bg-white dark:bg-boxdark-2   z-10 duration-300"
                                : "fixed top-0 right-[-100%] w-[300px] foldsmall:w-[280px] h-screen bg-white dark:bg-boxdark-2  z-10 duration-300"
                            }
                          >
                            <AiOutlineClose
                              onClick={(e) => {
                                e.preventDefault();
                                setNav(!nav);
                              }}
                              size={30}
                              className="absolute right-4 text-black top-4 cursor-pointer dark:text-white"
                            />
                            {UserData ? (
                              <div className="p-4 dark:text-white">
                                <h2 className="text-2xl  font-bold flex  ">
                                  Hey! 
                                </h2>
                                 
                              </div>
                            ) : (
                              <div className="p-4 dark:text-white">
                                <button
                                  type="button"
                                  className="relative   rounded-md  dark:text-white bg-[#F84464] py-2 px-6 md:px-4 text-white duration-200 "
                                  onClick={() => handleProfileClick(true)}
                                >
                                  Sign In
                                </button>
                              </div>
                            )}

                            <nav>
                              <ul className="flex flex-col p-0 text-gray-800">
                                {UserData && UserType === "V" ? (
                                  <div>
                                    {menuItemsForVendor?.map(
                                      ({ logo1, logo2, text, link }, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="py-4 border dark:text-white dark:border-zinc-600"
                                          >
                                            <h1
                                              className=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setNav(false);
                                              }}
                                            >
                                              <Link
                                                to={link}
                                                className="text-sm text-gray-600 dark:text-white flex cursor-pointer w-full mx-auto"
                                              >
                                                <span className="text-xl px-2 text-gray-400">
                                                  {logo1 ? (
                                                    <span>{logo1}</span> // Display the icon
                                                  ) : (
                                                    <img
                                                      src={lmsicong}
                                                      alt="Fallback"
                                                      className="w-6 h-6"
                                                    />
                                                  )}
                                                </span>{" "}
                                                {text}{" "}
                                                <span className="justify-end ml-auto mr-3">
                                                  {logo2}
                                                </span>
                                              </Link>
                                            </h1>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                ) : UserData && UserType === "U" ? (
                                  <div>
                                    {menuItems.map(
                                      ({ logo1, logo2, text, link }, index) => (
                                        <div
                                          key={index}
                                          className="py-4 border dark:text-white dark:border-zinc-600"
                                        >
                                          <h1
                                            className=""
                                            onClick={(e) => {
                                              e.preventDefault();
                                              setNav(false);
                                            }}
                                          >
                                            <Link
                                              to={link}
                                              className="text-sm text-gray-600 dark:text-white flex cursor-pointer w-full mx-auto"
                                            >
                                              <span className="text-xl px-2 text-gray-400">
                                                {logo1 ? (
                                                  <span>{logo1}</span> // Display the icon
                                                ) : (
                                                  <img
                                                    src={lmsicong}
                                                    alt="Fallback"
                                                    className="w-6 h-6"
                                                  />
                                                )}
                                              </span>
                                              {text}
                                              <span className="justify-end ml-auto mr-3">
                                                {logo2}
                                              </span>
                                            </Link>
                                          </h1>
                                        </div>
                                      )
                                    )}
                                  </div>
                                ) : (
                                  <div>
                                    {menuItemsForWithoutLogin?.map(
                                      ({ logo1, logo2, text, link }, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className="py-3 border dark:text-white dark:border-zinc-600"
                                          >
                                            <h1
                                              className=""
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setNav(false);
                                              }}
                                            >
                                              <Link
                                                to={link}
                                                className="text-sm text-gray-600 dark:text-white flex cursor-pointer w-full mx-auto"
                                              >
                                                <span className="text-xl px-2 text-gray-400">
                                                  {logo1 ? (
                                                    <span>{logo1}</span> // Display the icon
                                                  ) : (
                                                    <img
                                                      src={lmsicong}
                                                      alt="Fallback"
                                                      className="w-6 h-6"
                                                    />
                                                  )}
                                                </span>{" "}
                                                {text}{" "}
                                                <span className="justify-end ml-auto mr-3">
                                                  {logo2}
                                                </span>
                                              </Link>
                                            </h1>
                                          </div>
                                        );
                                      }
                                    )}
                                  </div>
                                )}

                                {/*============LOG OUT==============*/}
                                {UserData ? (
                                  <button
                                    onClick={logout}
                                    className="border border-[#F84464] mx-2 p-2 text-[#F84464] font-bold rounded-md my-2"
                                  >
                                    Sign Out
                                  </button>
                                ) : (
                                  ""
                                )}
                              </ul>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 px-2 pb-3 pt-2">
                    <Link
                      to={`"/event"`}
                      className="bg-gray-900 text-white block rounded-md px-2 py-2 text-base font-medium"
                    >
                      Events
                    </Link>
                    {CategoryData?.map((item) => (
                      <Disclosure.Button
                        key={item.Id}
                        to={`/event/${item.Id}/${item.Slug}`}
                        className={classNames(
                          item.current
                            ? "bg-gray-900 text-white"
                            : "text-gray-300 hover:bg-gray-700 hover:text-white",
                          "block rounded-md px-2 py-2 text-base font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.Title}
                      </Disclosure.Button>
                    ))}
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </div>

        {/* ====================================NavBar 2 ==========================================*/}
        <div className=" ">
          <Disclosure as="nav" className="bg-gray-100  dark:bg-boxdark-2  ">
            {({ open }) => (
              <div>
                <div className="mx-auto container px-2 sm:px-6 lg:px-2 ">
                  <div className="relative  items-center justify-between w-full md:w-full md:min-w-[70px] md:overflow-hidden md:overflow-y-hidden scrollbar-width-custom-in-navbar">
                    <div className="absolute  w-full  left-0 flex items-center sm:hidden ">
                      {/* Mobile menu button*/}
                      {/*<Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-1 ">
                        <span className="absolute -inset-0.5" />
                        <span className="sr-only">Open main menu</span>
                        {open ? (
                          <XMarkIcon
                            className="block h-10 text-black dark:text-white w-10"
                            aria-hidden="true"
                          />
                        ) : (
                          <Bars3Icon
                            className="block h-10 text-black dark:text-white w-10"
                            aria-hidden="true"
                          />
                        )}
                      </Disclosure.Button>*/}

                      <button className="border border-gray-300 dark:border-zinc-600 rounded-full relative w-full">
                        <input
                          type="text"
                          name=""
                          value=""
                          onClick={() => setShowModal(true)}
                          className="pl-10 py-3 px-4 rounded-full xl:w-[28rem] lg:w-[24rem] md:w-[22rem] surface:w-[22rem] dark:bg-boxdark-2 dark:text-white text-xs w-full"
                          placeholder="Search Events"
                        />
                        <FaSearch className="absolute top-3 left-3 text-gray-500 font-semibold dark:text-white" />
                      </button>
                    </div>
                    <div className="flex flex-1 sm:items-stretch ">
                      <div className="hidden sm:block mr-auto ">
                        <div className="flex space-x-3 whitespace-nowrap">
                          <Link
                            to={"/event"}
                            className="rounded-md px-2 py-3 text-sm lg:text-base font-normal text-gray-950 dark:text-white"
                          >
                            Events
                          </Link>
                          {CategoryData?.map((item) => (
                            <Link
                              key={item.Id}
                              to={`/event/${item.Id}/${item.Slug}`}
                              className={classNames(
                                item.current
                                  ? " text-black dark:text-white "
                                  : "text-black dark:text-white  ",
                                "rounded-md px-2 py-3 text-sm lg:text-base font-normal"
                              )}
                              aria-current={item.current ? "page" : undefined}
                            >
                              {item.Title}
                            </Link>
                          ))}
                        </div>
                      </div>

                      <div className="hidden sm:block ml-auto justify-end">
                        <div className="flex space-x-3 justify-end whitespace-nowrap">
                          {UserData && UserType === "V" ? (
                            <Link
                              to={"/list-show"}
                              className="text-black dark:text-white   rounded-md px-2 py-3 text-sm lg:text-base font-normal"
                            >
                              List Your Show
                            </Link>
                          ) : (
                            <Link
                              to={"/list-show-signin"}
                              className="text-black dark:text-white   rounded-md px-2 py-3 text-sm lg:text-base font-normal"
                            >
                              List Your Show
                            </Link>
                          )}

                          {/* <Link
                            to={"/offers"}
                            className="text-black dark:text-white   rounded-md px-2 py-3 text-sm lg:text-base font-normal"
                          >
                            Offers
                          </Link>*/}
                          <Link
                            to={"/artist"}
                            className="text-black dark:text-white   rounded-md px-2 py-3 text-sm lg:text-base font-normal"
                          >
                            Artist
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*=======================Mobile menu=====================*/}
                <Disclosure.Panel className="sm:hidden">
                  <div className="space-y-1 px-2 pb-3 mt-16">
                    <Link
                      to={"/event"}
                      className="rounded-md px-2 py-1 text-sm text-gray-500 font-medium dark:text-white"
                    >
                      Events
                    </Link>
                    {CategoryData?.map((item) => (
                      <Link
                        key={item.Id}
                        to={`/event/${item.Id}/${item.Slug}`}
                        className={classNames(
                          item.current ? "text-gray-500" : "text-gray-500",
                          "block px-2 py-1 text-sm font-medium  dark:text-white"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.Title}
                      </Link>
                    ))}

                    {UserData && UserType === "V" ? (
                      <Link
                        to={"/list-show"}
                        className="block rounded-md px-3 py-1 text-sm text-gray-500 font-medium dark:text-white"
                      >
                        List Your Show
                      </Link>
                    ) : (
                      <Link
                        to={"/list-show-signin"}
                        className="rounded-md px-2 py-1 text-sm text-gray-500 font-medium dark:text-white"
                      >
                        List Your Show
                      </Link>
                    )}
 
                    <Link
                      to={"/artist"}
                      className="block rounded-md px-2 py-1 text-gray-500 text-sm font-medium dark:text-white"
                    >
                      Artist
                    </Link>
                  </div>
                </Disclosure.Panel>
              </div>
            )}
          </Disclosure>
        </div>
      </section>
      {/*============================= Bottom Navbar ==========================================*/}

      <div className="bottomNavbar lg:hidden fixed bottom-0 left-0 z-50 w-full h-16 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-zinc-600">
        <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
          {bottomNavdata.map(({ id, label, icons, link }) => (
            <div key={id} className="block m-auto">
              <Link
                to={link}
                onClick={
                  id === "4" ? handleProfileClick : () => clickedEvent(id)
                }
                className={`focus:outline-none ${
                  active === id
                    ? "text-themecolor1"
                    : "text-black dark:text-white"
                }`}
              >
                <h1 className="flex justify-center text-xl">
                  {icons ? (
                    <span className="text-black dark:text-white">{icons}</span> // Display the icon
                  ) : (
                    <>
                      <img
                        src={lmsiconb}
                        alt="Love my show"
                        className="w-[22px] h-[22px] dark:hidden" // Show this image in light mode
                      />
                      <img
                        src={lmsiconw}
                        alt="Love my show"
                        className="w-[22px] h-[22px] hidden dark:block" // Show this image in dark mode
                      />
                    </>
                  )}
                </h1>
               <span className=" md:text-normal text-sm">{label}</span>
              </Link>
            </div>
          ))}
          {/* Render the Profile link separately */}
          <div key={profileLink.id} className="block m-auto">
            <Link
              to={profileLink.link}
              onClick={(e) => {
                e.preventDefault();
                setNav(!nav);
              }}
              className={`focus:outline-none ${
                active === profileLink.id
                  ? "text-white"
                  : "text-black dark:text-white"
              }`}
            >
              <h1 className="flex justify-center text-xl">
                {profileLink.icons}
              </h1>
              <span className=" md:text-normal text-sm">{profileLink.label}</span>
        
            </Link>
          </div>
        </div>
      </div>

      {loginpopup ? (
        <div>
          <div className="px-3 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative h-80 flex flex-col w-full bg-white  outline-none dark:bg-boxdark-2 dark:text-white   focus:outline-none">
                <div className="flex items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t dark:border-zinc-600">
                  <h3 className="text-xl  block font-semibold">Login</h3>
                  <button
                    className="text-themecolor1 background-transparent font-bold uppercase  text-3xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setloginpopup(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                {isMobileScreen ? (
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <p className="text-center text-base mt-4 mb-2">
                        Enter Mobile No
                      </p>
                      <div className="p-6 pt-2 border-solid border-blueGray-200 pb-6 rounded-b">
                        <div className="flex border-0 w-full">
                          <input
                            type="text"
                            name="EOP"
                            autoFocus
                            value={formik.values.EOP}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={phoneLengthByCountry[getCountrySlug] || 10}
                            className="border dark:border-zinc-600 outline-none w-full px-4 py-3 text-sm rounded-md dark:bg-boxdark-2 dark:text-white"
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                        {formik.touched.EOP && formik.errors.EOP ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.EOP}
                          </div>
                        ) : null}
                      </div>
                      <div className="px-6">
                        <button
                          className="bg-themecolor1 p-2 w-full text-white rounded-md"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                      <p className="text-xs block px-6 m-auto text-gray-500 py-5 dark:text-white">
                        I agree to the{" "}
                        <Link
                          to={"/page/terms-and-conditions"}
                          className="underline decoration-1"
                        >
                          Terms & conditions
                        </Link>{" "}
                        &{" "}
                        <Link
                          to={"/page/privacy-policy"}
                          className="underline decoration-1"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </form>
                  </div>
                ) : (
                  <div className="my-auto">
                    <form onSubmit={formik2.handleSubmit}>
                      <p className="text-center text-xl">Enter OTP</p>
                      <div className="p-3  w-full flex justify-center">
                        <input
                          type="text"
                          placeholder="****"
                          name="OTP"
                          autoFocus
                          maxLength={4}
                          value={formik2.values.OTP}
                          onChange={formik2.handleChange}
                          className="border text-center w-full dark:bg-boxdark-2 dark:text-white py-3  mx-2 tracking-wide"
                        />
                      </div>
                      {formik2.touched.OTP && formik2.errors.OTP ? (
                        <div className="text-red-500 text-xs mt-2 px-5">
                          {formik2.errors.OTP}
                        </div>
                      ) : null}

                      <div className="text-center text-xs mt-3">
                        {showResend ? (
                          <>
                            OTP expired?{" "}
                            <button
                              onClick={handleResendOTP}
                              type="button"
                              className="underline text-themecolor1"
                            >
                              Resend OTP
                            </button>
                          </>
                        ) : (
                          `Resend OTP in ${formatTimer()}`
                        )}
                      </div>
                      <div className="grid grid-cols-2 mt-7">
                        <button
                          className="bg-themecolor1 mx-5 p-2 text-white rounded-md"
                          onClick={() => setIsMobileScreen(true)}
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="bg-themecolor1 mx-5 p-2 text-white text-center rounded-md"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}

      {/* ================================Event search popup============================================ */}

      {showModal ? (
        <div>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative h-full w-full">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col bg-white dark:bg-boxdark-2 outline-none focus:outline-none h-full">
                <div className="flex flex-col items-center justify-center md:p-5 py-20 px-4 h-full border-b dark:border-zinc-600 border-solid border-blueGray-200 rounded-t bg-gray-200 dark:bg-boxdark-2">
                  <form
                    onSubmit={handleSearch}
                    className="lg:w-[80%] md:w-[75%] w-[90%] flex justify-center items-center"
                  >
                    <input
                      className="py-3 w-full block m-auto border px-3 md:px-5 outline-none text-sm text-gray-700 pr-2"
                      type="text"
                      id="search"
                      value={searchKeyword}
                      onChange={(e) => setSearchKeyword(e.target.value)}
                      autoFocus
                      placeholder="Search Events"
                    />
                    <input
                      type="submit"
                      value="Search"
                      className="bg-themecolor1 px-4 h-11 text-white font-bold my-auto cursor-pointer"
                    />
                  </form>

                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 bg-white p-3 mt-5 overflow-scroll lg:w-[80%] md:w-[75%] w-[90%]">
                    {TypeCategoryEventData?.map((categoryType, index) => {
                      return (
                        <section key={index}>
                          <div className="container mx-auto px-5">
                            <div className="flex px-2 items-center my-3 border-b pb-2">
                              <h1 className="text-xl font-semibold maintitle text-themecolor1 ">
                                {categoryType.CategoryTitle}
                              </h1>
                            </div>
                            {categoryType.length === 0 ? (
                              <div
                                className={`mx-auto bg-gray-200 font-bold text-2xl text-bodydark2 text-center  border dark:border-zinc-600 shadow-lg"
                          }`}
                              >
                                <NoDataFound />
                              </div>
                            ) : (
                              <>
                                {categoryType?.Events?.slice(0, 5).map(
                                  (event, eventIndex) => (
                                    <div key={eventIndex}>
                                      <div>
                                        <Link
                                          to={`/event-detail/${event.EventSlug}/${event.Id}`}
                                          onClick={() => setShowModal(false)}
                                        >
                                          <div className="px-2 py-2">
                                            <h1 className="md:font-bold font-semibold text-sm line-clamp-1">
                                              {event.EventName}
                                            </h1>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                )}
                              </>
                            )}
                          </div>
                        </section>
                      );
                    })}
                  </div>
                </div>

                <div className="absolute top-0 right-0 mt-4 mr-4 bg-white p-3 pb-2">
                  <button
                    onClick={() => setShowModal(false)}
                    className="text-gray-700 dark:text-black"
                  >
                    <FaX className="text-xl " />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Navbar;
