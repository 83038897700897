/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { FaLocationDot } from "react-icons/fa6";
import { FaCalendar, FaDollarSign, FaRupeeSign } from "react-icons/fa";
import usePageSEO from "../hoosk/usepageSEO/Index";
import { getEventBySlugEventId } from "../API/EventApi";
import c1 from "../../Images/cg.png";
import c2 from "../../Images/cp.png";
import c3 from "../../Images/c3.png";

import co1 from "../../Images/coupon.jpg";
import co2 from "../../Images/co2.jpg";
import co3 from "../../Images/co3.jpg";

import c4 from "../../Images/c5.png";
import kartikaryan from "../../Images/kartikaryan.mp4";
import Config from "../API/Config";
import { sendOTP, verifyOTP } from "../API/LoginApi";
import { useFormik } from "formik";
import * as yup from "yup";
import { RxCross2 } from "react-icons/rx";
import ClipLoader from "react-spinners/ClipLoader";
import FormLoader from "../common/FormLoader";
import ScrollToTop from "../ScrollToTop";
import { Tooltip } from "react-tooltip";

const phoneLengthByCountry = {
  India: 10, // India
  Australia: 10, // Australia
  UK: 10, // UK
  USA: 10, // USA
  Canada: 10, // Canada
  // Add other countries and their rules here
};
const getPhoneValidationSchema = (countryCode) => {
  const phoneLength = phoneLengthByCountry[countryCode] || 10;
  return yup
    .string()
    .required(`Phone number is required for ${countryCode}`)
    .test(
      "is-valid-length",
      `Enter a valid phone number of length ${phoneLength}`,
      (value) => value && value.replace(/\D/g, "").length === phoneLength
    );
};
const validationSchema2 = yup.object().shape({
  OTP: yup
    .string()
    .matches(/^[0-9]+$/, "OTP must contain only numbers")
    .length(4, "OTP must be exactly 4 digits")
    .required("OTP is required"),
});

const EventBook = () => {
  const { Id } = useParams();
  const UserId = Config.getId();
  const getCountrySlug = Config.getCountrySlug();
  const validationSchema = yup.object().shape({
    EOP: yup.string().when("Country", {
      is: (value) => !!value, // Ensure Country is selected
      then: (value) => getPhoneValidationSchema(getCountrySlug),
      otherwise: yup.string().required("Phone number is required"),
    }),
  });
  // --------------------Login-----------------
  const [loginpopup, setloginpopup] = useState(false);

  const [isMobileScreen, setIsMobileScreen] = useState(true);
  // -----------------------otpsend-------------------------

  const [showResend, setShowResend] = useState(false);
  const [timer, setTimer] = useState(60); // 60 seconds = 1 minute
  // -----------------------otpsend-------------------------
  useEffect(() => {
    let interval;
    if (timer > 0) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else {
      setShowResend(true);
    }

    return () => clearInterval(interval);
  }, [timer]);

  const formik = useFormik({
    initialValues: {
      EOP: "", // Add EOP field
      Country: getCountrySlug,
      Type: "U",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await sendOTP(values);
        if (response.status === true) {
          setIsMobileScreen(false);
        }
        setIsMobileScreen(false);
        formik2.setFieldValue("EOP", values.EOP);
        setResendOTP((prevState) => ({
          ...prevState,
          EOP: values.EOP, // Update EOP in ResendOTP state
        }));
        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });
  const formik2 = useFormik({
    initialValues: {
      EOP: "",
      Country: getCountrySlug,
      Type: "U",
      OTP: "",
    },
    validationSchema: validationSchema2,
    onSubmit: async (values, actions) => {
      setIsFormLoading(true);
      try {
        const response = await verifyOTP(values);
        if (response.status === true) {
          window.location.reload();
          setloginpopup(false);
        }

        actions.resetForm();
      } catch (error) {
        console.error("Error :", error);
      } finally {
        setIsFormLoading(false); // Set loading state to false when submission ends
      }
    },
  });

  const [ResendOTP, setResendOTP] = useState({
    EOP: "",
    Country: getCountrySlug,
    Type: "U",
  });
  const handleResendOTP = async () => {
    setIsFormLoading(true);
    try {
      setTimer(60);
      setShowResend(false);
      await sendOTP(ResendOTP); // Replace with actual function call
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setIsFormLoading(false); // Set loading state to false when submission ends
    }
  };
  const formatTimer = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };
  // const handleLoginClick = () => {
  //   toast.info("To Like a event, you must be registered.", {
  //     autoClose: 2000,
  //     onClose: () => {
  //       setloginpopup(true);
  //     },
  //   });
  // };
  // ------------------------getEventBySlugEventId-----------------------
  const CityId = Config.getCityIdData();
  const { Slug } = useParams();

  const [EventDetailData, setEventDetailData] = useState([]);
  const [loading, setLoading] = useState(true); // Loading state

  //=======================================You may also like================================
  const fetchEventDetailData = async () => {
    try {
      const result = await getEventBySlugEventId(CityId, Slug, Id);
      setEventDetailData(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchEventDetailData();
  }, [CityId, Slug, Id]);

  const EventId = Id;
  const [interestedClicked, setInterestedClicked] = useState(false);
  // const [count, setCount] = useState(EventDetailData.EventLikes || 0);
  const [isFormLoading, setIsFormLoading] = useState(false);

  useEffect(() => {
    setInterestedClicked(EventDetailData?.UserLikesEvent || false); // Assuming EventDetailData has a field indicating if the user liked the event
  }, [EventDetailData]);

  const EventID = EventDetailData?.Id;

  const formatHours = (hours) => {
    const normalizedHours = hours % 12 || 12; // Convert to 12-hour format, with 12 as special case
    return normalizedHours.toString().padStart(2, "0");
  };
  // Convert EventStartDateTime to a Date object
  // Function to format the hours to 12-hour format

  // Assume EventDetailData has the EventStartDateTime field
  const startDate = new Date(EventDetailData?.EventStartDateTime);

  const formattedStartDate = `${startDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${startDate.getFullYear()} ${formatHours(
    startDate.getHours()
  )}:${startDate.getMinutes().toString().padStart(2, "0")} ${
    startDate.getHours() >= 12 ? "PM" : "AM"
  }`;

  const formattedStartDataOnlyDate = `${startDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(startDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${startDate.getFullYear()}`;

  const currentDate = new Date();
  const formattedcurrentDate = `${currentDate
    .getDate()
    .toString()
    .padStart(2, "0")}/${(currentDate.getMonth() + 1)
    .toString()
    .padStart(2, "0")}/${currentDate.getFullYear()}`;

  usePageSEO(
    EventDetailData
      ? `${EventDetailData?.SeoTitle}`
      : "Love My Show | Booking - Event",
    EventDetailData
      ? `${EventDetailData?.SeoDescription}`
      : "Love My Show | Booking - Event",
    [
      EventDetailData
        ? `${EventDetailData?.SeoKeyword}`
        : "Love My Show | Booking - Event",
    ]
  );
 

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isBlinking, setIsBlinking] = useState(false);
  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };
  const startBlinking = () => {
    setIsBlinking(true);
  };
  return (
    <div className="  dark:bg-boxdark-2 relative">
      <ScrollToTop />
      <button
        onClick={openPopup}
        onMouseEnter={startBlinking}
        className={`blink-btn fixed bottom-1/4 -right-[85px] z-50 transition-all duration-300 text-xs tracking-widest ease-in-out transform rotate-90 px-3 py-3 rounded-full text-white uppercase shadow-lg ${
          isBlinking ? "blink" : ""
        }`}
        style={{
          background: isBlinking
            ? "linear-gradient(90deg, #e3336c, green)" // Gradient colors when blinking
            : "linear-gradient(90deg, green, #e3336c)", // Regular gradient
          border: "none",
          cursor: "pointer",
          outline: "none",
        }}
      >
        <span className="text-lg">✨ Offer Blast! ✨</span>
      </button>
      <div className="app">
        {isPopupOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-70 z-50">
            <div className="bg-white rounded-lg shadow-2xl p-6 relative w-full max-w-sm mx-5 my-10">
              <button
                className="absolute top-2 right-2 text-gray-600 hover:text-red-500 text-2xl transition-colors duration-300"
                onClick={closePopup}
                style={{
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >
                &times; {/* Close button */}
              </button>
              <h2 className="text-2xl font-bold mb-4 text-center text-gray-800">
                10 October - Day 8
              </h2>

              <div className="w-full mb-4">
                <Link to={"https://lovemyshow.com/event/booking-steps/1/1/8"}>
                  <video
                    className="w-96 h-auto rounded-lg shadow-md"
                    autoPlay
                    loop
                  >
                    <source src={kartikaryan} type="video/mp4" />
                  </video>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      {isFormLoading && <FormLoader loading={isFormLoading} />}
      {loading ? (
        <div className="flex justify-center items-center my-48">
          <ClipLoader color={"#DC3558"} loading={loading} size={30} />
        </div>
      ) : (
        <div className="container md:px-5 px-3 mx-auto  md:pt-4">
          <div className="bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-t-lg shadow-md">
            <div className="relative">
              <img
                src={EventDetailData?.Banner}
                alt=""
                className="block rounded-t-lg w-full md:h-[450px] h-[150px]"
              />
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4 shadow-md bg-white dark:bg-boxdark-2 dark:text-white">
              <div className="md:col-span-3">
                <h1 className="md:text-2xl text-xl font-bold py-1.5 px-4">
                  {EventDetailData?.EventName}
                </h1>
                <p className="md:text-lg text-sm font-semibold px-4 md:pb-3 flex items-center">
                  Start From
                  <span className="text-themecolor1 font-bold flex items-center text-xl ml-2">
                    {getCountrySlug === "India" ? (
                      <>
                        <span className="text-sm">
                          <FaRupeeSign />
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="text-sm">
                          <FaDollarSign />
                        </span>
                      </>
                    )}
                    {
                      EventDetailData?.Addresses[0]?.Dates[0]?.Tickets[0]
                        ?.TicketAmount
                    }
                  </span>
                  <span className="flex justify-end items-end ml-auto py-1.5 mb:py-0">
                    {formattedcurrentDate <= formattedStartDataOnlyDate ? (
                      <Link
                        to={{
                          pathname: `/event/booking-steps/${EventID}`,
                        }}
                      >
                        <button className=" bg-green-600 hover:bg-green-700 px-6 md:py-3 py-1 md:px-11 md:font-semibold rounded-md md:rounded-lg text-sm md:text-lg  text-white ">
                          Book
                        </button>
                      </Link>
                    ) : (
                      <p className=" md:py-2 py-1 md:px-5 text-md font-normal text-gray-500 dark:text-white ">
                        <span className="text-sm font-semibold">
                          Event Booking Start On -
                        </span>{" "}
                        {EventDetailData?.EventStartDateTime}
                      </p>
                    )}
                  </span>
                </p>
              </div>
            </div>
            <div className="grid md:flex border-t shadow-md dark:border-zinc-600 border-gray-200 bg-white dark:bg-boxdark-2 dark:text-white py-4 ">
              <h1 className="px-4 md:border-r border-gray-300 dark:border-white text-sm">
                <span className="text-sm font-semibold">
                  Event Booking Start On -{" "}
                </span>{" "}
                <span className="text-gray-600 dark:text-white text-md">
                  {" "}
                  {EventDetailData?.EventStartDateTime}
                </span>
              </h1>
              <h1 className="flex md:border-r border-gray-100 text-sm mt-2 md:mt-0 dark:border-white px-2">
                <FaLocationDot className="text-yellow-400 text-base mx-2" />
                {EventDetailData?.Addresses?.map((val, index) => {
                  return (
                    <>
                      {index > 0 && ", "}
                      {val.CityTitle}
                    </>
                  );
                })}
              </h1>
              <h1 className="flex px-4">
                {EventDetailData?.Addresses?.map((address) =>
                  address?.Tickets?.map((ticket, index) => (
                    <React.Fragment key={ticket.Id}>
                      {index > 0 && ", "}
                      <React.Fragment>
                        {getCountrySlug === "India" ? (
                          <>
                            (
                            <span className="text-sm">
                              <FaRupeeSign />
                            </span>
                            )
                          </>
                        ) : (
                          <>
                            (
                            <span className="text-sm">
                              <FaDollarSign />
                            </span>
                            )
                          </>
                        )}
                        {ticket.TicketAmount}
                      </React.Fragment>
                    </React.Fragment>
                  ))
                )}
              </h1>
            </div>
          </div>

          <div className="grid my-3 md:gap-2 lg:grid-cols-1 md:grid-cols-1 grid-cols-1">
            <div className="grid my-3 mb-0 md:gap-2 ">
              <div className="w-full bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md my-2">
                <a href="tel:+918866560333">
                  <img src={co1} className="md:w-2/3 w-full mx-auto " alt="" />
                </a>
              </div>
            </div>
            
          </div>

          {/*=================================== Artist Data ====================================*/}
          <div className="lg:grid lg:grid-cols-4 lg:gap-6 py-9 pt-4">
            <div className="w-full ">
              {EventDetailData?.ArtistTitle && (
                <div className="p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md mb-4">
                  <h1 className="border-b text-base text-black dark:text-white font-medium pb-2 mb-4 dark:border-zinc-700">
                    Artist
                  </h1>
                  <Link
                    to={`/artist/${EventDetailData?.ArtistSlug}`}
                    className="flex items-center gap-4 lg:flex-row md:flex-row flex-col mx-auto"
                  >
                    <img
                      src={EventDetailData?.ArtistImage}
                      alt="Artist"
                      className="w-24 h-24 rounded-full bg-white shadow-lg border dark:border-zinc-600 border-gray-200"
                    />
                    <h1 className="text-sm font-medium text-gray-600 dark:text-white flex items-center">
                      {EventDetailData?.ArtistTitle}
                    </h1>
                  </Link>
                </div>
              )}

              <div className="p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md mb-4">
                <h1 className="border-b text-base text-black dark:text-white font-medium pb-2 mb-4 dark:border-zinc-700">
                  Point of Contact
                </h1>
                <div className="space-y-2 dark:text-white">
                  <h2 className="text-sm font-medium">
                    <span className="font-semibold">Name:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {EventDetailData?.PointOfContactName}
                    </span>
                  </h2>
                  <h2 className="text-sm font-medium">
                    <span className="font-semibold">Email:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {" "}
                      {EventDetailData?.PointOfContactEmail}
                    </span>
                  </h2>
                  <h2 className="text-sm font-medium">
                    <span className="font-semibold">Phone:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {" "}
                      {EventDetailData?.PointOfContactPhone}
                    </span>
                  </h2>
                </div>
              </div>

              <div className="p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md mb-4">
                <h1 className="border-b text-base text-black dark:text-white font-medium pb-2 mb-4 dark:border-zinc-700">
                  Event Information
                </h1>
                <div className="space-y-3 dark:text-white">
                  <div className="text-sm font-medium">
                    <span className="font-semibold ">Event Organizer:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {" "}
                      {EventDetailData?.EventOrganizer}
                    </span>
                  </div>
                  <div className="text-sm font-medium">
                    <span className="font-semibold">Age Restriction:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {" "}
                      {EventDetailData?.AgeRestriction}
                    </span>
                  </div>
                  <div className="text-sm font-medium">
                    <span className="font-semibold">
                      Healthy & Safety Precautions:
                    </span>
                    <p className="pt-1">
                      {EventDetailData?.HealthySafetyPrecautions}
                    </p>
                  </div>
                  <div className="text-sm font-medium">
                    <span className="font-semibold">Category:</span>{" "}
                    <span className="text-sm text-gray-600 dark:text-white font-medium">
                      {" "}
                      {EventDetailData?.CategoryTitle}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            {/*=================================== Interested Part ====================================*/}

            <div className="-mx-0 col-span-2 md:w-full ">
              {/*=================================== About Part ====================================*/}

              <div className="p-4 bg-white dark:bg-gray-800  border border-gray-200 dark:border-gray-700 rounded-lg shadow-md mb-4">
                <h1 className="border-b text-base text-black dark:text-white font-medium pb-2 mb-4 dark:border-zinc-700">
                  About Event
                </h1>
                <div className="px-0">
                  <p
                    className="text-sm text-justify bg-white dark:bg-transparent dark:text-gray-300"
                    dangerouslySetInnerHTML={{
                      __html: EventDetailData
                        ? EventDetailData.EventDescription
                        : "",
                    }}
                  ></p>
                </div>
              </div>
            </div>

            {/*========================================= Location Part =================================*/}
            <div className="mb-5">
              {EventDetailData?.Addresses?.map((location, index) => (
                <div
                  key={index}
                  className="p-4 bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 rounded-lg shadow-md mb-4"
                >
                  <h1 className="border-b flex items-center text-base text-black dark:text-white font-medium pb-2 mb-2 dark:border-zinc-700">
                    <FaLocationDot className="text-green-500 text-base dark:text-blue-400 mr-1" />
                    {location.CityTitle}
                  </h1>

                  <div className="mb-3">
                    <span className="text-sm font-medium text-gray-700 dark:text-gray-300 border-b border-gray-200 dark:border-gray-700 pb-2 block">
                      Dates of this location
                    </span>
                    {location?.Dates.map((date, index) => (
                      <div
                        key={index}
                        className="flex items-center text-xs text-gray-500 dark:text-gray-400 py-2 border-b"
                      >
                        <FaCalendar className="text-themecolor1 dark:text-green-400 mr-2" />
                        {date.EventDate} ({date.EventStartTime} -{" "}
                        {date.EventEndTime})
                      </div>
                    ))}
                  </div>

                  <div className="relative">
                    <div
                      className="map-container border border-gray-300 dark:border-gray-600 overflow-hidden"
                      dangerouslySetInnerHTML={{
                        __html: location.Address || "",
                      }}
                    ></div>
                    <Tooltip
                      id={`map-tooltip-${index}`}
                      place="bottom"
                      effect="solid"
                      className="bg-gray-800 text-white"
                    >
                      View on Map
                    </Tooltip>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {loginpopup ? (
        <div>
          <div className="px-3 justify-center items-center flex overflow-x-hidden overflow-y-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative  my-6 mx-auto w-96">
              <div className="border-0 rounded-lg shadow-lg relative h-80 flex flex-col w-full bg-white  outline-none dark:bg-boxdark-2 dark:text-white   focus:outline-none">
                <div className="flex items-start  justify-between p-5 border-b border-solid border-blueGray-200 rounded-t dark:border-zinc-600">
                  <h3 className="text-xl  block font-semibold">Login</h3>
                  <button
                    className="text-themecolor1 background-transparent font-bold uppercase  text-3xl outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setloginpopup(false)}
                  >
                    <RxCross2 />
                  </button>
                </div>
                {isMobileScreen ? (
                  <div>
                    <form onSubmit={formik.handleSubmit}>
                      <p className="text-center text-xl mt-2">
                        Enter Mobile No
                      </p>
                      <div className="p-6 pt-2 border-solid border-blueGray-200 pb-6 rounded-b">
                        <div className="flex border-0 w-full">
                          <input
                            type="text"
                            name="EOP"
                            autoFocus
                            value={formik.values.EOP}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            maxLength={
                              phoneLengthByCountry[getCountrySlug] || 10
                            }
                            className="border dark:border-zinc-600 outline-none w-full p-2 rounded-md dark:bg-boxdark-2 dark:text-white"
                            placeholder="Enter Mobile Number"
                          />
                        </div>
                        {formik.touched.EOP && formik.errors.EOP ? (
                          <div className="text-red-500 text-xs mt-2">
                            {formik.errors.EOP}
                          </div>
                        ) : null}
                      </div>
                      <div className="px-6">
                        <button
                          className="bg-themecolor1 p-2 w-full text-white rounded-md"
                          type="submit"
                        >
                          Continue
                        </button>
                      </div>
                      <p className="text-xs block px-6 m-auto text-gray-500 py-5 dark:text-white">
                        I agree to the{" "}
                        <Link
                          to={"/page/terms-and-conditions"}
                          className="underline decoration-1"
                        >
                          Terms & conditions
                        </Link>{" "}
                        &{" "}
                        <Link
                          to={"/page/privacy-policy"}
                          className="underline decoration-1"
                        >
                          Privacy Policy
                        </Link>
                      </p>
                    </form>
                  </div>
                ) : (
                  <div className="my-auto">
                    <form onSubmit={formik2.handleSubmit}>
                      <p className="text-center text-xl">Enter OTP</p>
                      <div className="p-3  w-full flex justify-center">
                        <input
                          type="text"
                          placeholder="****"
                          name="OTP"
                          autoFocus
                          maxLength={4}
                          value={formik2.values.OTP}
                          onChange={formik2.handleChange}
                          className="border text-center w-full dark:bg-boxdark-2 dark:text-white py-3  mx-2 tracking-wide"
                        />
                      </div>
                      {formik2.touched.OTP && formik2.errors.OTP ? (
                        <div className="text-red-500 text-xs mt-2 px-5">
                          {formik2.errors.OTP}
                        </div>
                      ) : null}
                      <div className="text-center text-xs mt-3">
                        {showResend ? (
                          <>
                            OTP expired?{" "}
                            <button
                              onClick={handleResendOTP}
                              type="button"
                              className="underline text-themecolor1"
                            >
                              Resend OTP
                            </button>
                          </>
                        ) : (
                          `Resend OTP in ${formatTimer()}`
                        )}
                      </div>
                      <div className="grid grid-cols-2 mt-7">
                        <button
                          className="bg-themecolor1 mx-5 p-2 text-white rounded-md"
                          onClick={() => setIsMobileScreen(true)}
                          type="button"
                        >
                          Back
                        </button>
                        <button
                          type="submit"
                          className="bg-themecolor1 mx-5 p-2 text-white text-center rounded-md"
                        >
                          Verify OTP
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
        </div>
      ) : null}
    </div>
  );
};

export default EventBook;
